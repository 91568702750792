import * as React from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import SanitizedHtml from '@ui/Blocks/Shared/SanitizedHtml'

interface NppTooltipProps extends TooltipProps {
  title: any
}

const NppTooltip: React.FC<NppTooltipProps> = ({
  children,
  className = "",
  title = "",
  ...rest
}) => {
  return (
    <Tooltip
      {...rest}
      className={`NppTooltip__wrapper ${className}`}
      title={<SanitizedHtml htmlContent={title} />}
    >
      {children}
    </Tooltip>
  );
};

NppTooltip.defaultProps = {
  className: "",
  title: "",
};

export default NppTooltip;
