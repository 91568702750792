// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import RailsUjs from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import ReactOnRails from 'react-on-rails'
import NppApp from '../bundles/startup/ClientRouterApp'

RailsUjs.start()
ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

ReactOnRails.setOptions({
  // traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
  // process.env.TRACE_TURBOLINKS -> error: development is not defined
  traceTurbolinks: true,
})

ReactOnRails.register({
  NppApp,
})

// Stack Admin
require('popper.js')
require('bootstrap')
require('perfect-scrollbar')
require('../../../dme-common/assets/src/stack-admin/vendors/js/ui/unison.min.js')
require('../../../dme-common/assets/src/stack-admin/vendors/js/forms/icheck/icheck.min')
require('../../../dme-common/assets/src/stack-admin/vendors/js/forms/validation/jqBootstrapValidation')
import('../../../dme-common/assets/src/stack-admin/src/js/core/app-menu')
import('../../../dme-common/assets/src/stack-admin/src/js/core/app')
import('../../../dme-common/assets/src/stack-admin/src/js/scripts/forms/form-login-register')
