import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getSeatUsageReportApi } from '@services/ApiServices'
import BaseSetting from '@config/BaseSetting'

interface getSeatUsageReportApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

// Specify the payload type explicitly here
interface PayloadInterface {
  page: number
  sort_column: number
  sort_direction: string
  partner_ids: string[]
  page_size: number
  page_action: string
  pagination_state?: string
}

const getSeatUsageReportAsync = createAsyncThunk(
  'SeatUsageReport/getSeatUsageReport',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getSeatUsageReportApi({ ...payload, csrfToken: BaseSetting.csrfToken })
    return data as getSeatUsageReportApiData // Adjust the type accordingly
  }
)

const resetSeatUsageReport = createAction('SeatUsageReport/resetSeatUsageReport')

const getSeatUsageReportExtraReducers = (builder: any) => {
  builder
    .addCase(getSeatUsageReportAsync.pending, (state) => {
      state.getSeatUsageReport = true
    })
    .addCase(getSeatUsageReportAsync.rejected, (state) => {
      state.getSeatUsageReport = false
    })
    .addCase(getSeatUsageReportAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.seatUsageReport = action.payload.data
      } else {
        state.error = action.payload.error
      }
      state.getSeatUsageReport = false
    })
    .addCase(resetSeatUsageReport, (state) => {
      state.seatUsageReport = {}
      state.getSeatUsageReport = false
      state.error = null
    })
}

export { getSeatUsageReportAsync, getSeatUsageReportExtraReducers, resetSeatUsageReport }
