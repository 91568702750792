import i18n from 'i18n'
import DateTimeFactory from '@libs/DateTime'
import BaseSetting from '@config/BaseSetting'

const DEFAULT_FULL_DATE_TIME = 'FULL_DATE_TIME' // eg: Thursday, 28 February 2019 at 22:55
const DEFAULT_DATE_TIME = 'DATE_TIME' // eg: 28 Feb 2019 22:55
const DEFAULT_DATE_TIME_AT = 'DATE_TIME_AT' // eg: 28 Feb 2019 at 22:55
const DEFAULT_DATE = 'DATE' // eg: 28 Feb 2019
const DEFAULT_FULL_DATE = 'DATE_DETAIL' // eg: 28 February 2019
const DEFAULT_TIME = 'TIME' // eg: 22:55

interface DateFormat {
  DEFAULT_FULL_DATE_TIME: string
  DEFAULT_DATE_TIME: string
  DEFAULT_DATE_TIME_AT: string
  DEFAULT_DATE: string
  DEFAULT_FULL_DATE: string
  DEFAULT_TIME: string
}

const dateClient = new DateTimeFactory({ parseFormat: BaseSetting.backendDateTimeFormat }).create()

interface DateTimeHook {
  dateToString?: (format: string, date: Date) => string | null
  dateFormat?: DateFormat
  dateDistance?: (date1: Date, date2: Date, options: { separator?: string }) => string
  dateTimeNow?: () => Date
  mergeDateAndTime?: (date: Date, time: Date) => Date
  equalDateValidation?: (firstDate: Date, secondDate: Date) => boolean
  toISOFormat?: (date: Date, time: Date) => string
  dateTimeNowObject?: () => Date
  stringToDate?: (date: string) => Date
  sortArrayDate?: (dates: Date[]) => Date[]
  addDateTime?: (date: Date, amount: number, unit: string) => Date
}

export default function useDateTime(): DateTimeHook {
  const { t } = i18n

  const dateFormat: DateFormat = {
    DEFAULT_FULL_DATE_TIME,
    DEFAULT_DATE_TIME,
    DEFAULT_DATE_TIME_AT,
    DEFAULT_DATE,
    DEFAULT_FULL_DATE,
    DEFAULT_TIME
  }

  const dateToString = (format: string, date: Date): string | null => {
    if (!date) return null

    switch (format) {
      case DEFAULT_FULL_DATE_TIME:
        return dateClient.toString(date, 'dddd, D MMMM YYYY [at] HH:mm')
      case DEFAULT_DATE_TIME:
        return dateClient.toString(date, 'D MMM YYYY HH:mm')
      case DEFAULT_DATE_TIME_AT:
        return dateClient.toString(date, 'D MMM YYYY [at] HH:mm')
      case DEFAULT_DATE:
        return dateClient.toString(date, 'D MMM YYYY')
      case DEFAULT_FULL_DATE:
        return dateClient.toString(date, 'DD MMMM YYYY')
      case DEFAULT_TIME:
        return dateClient.toString(date, 'hh:mm')
      default:
        throw Error('Date time format is not defined')
    }
  }

  const dateTimeNow = (): Date => {
    return dateClient.dateTimeNow()
  }

  return {
    dateToString,
    dateFormat,
    dateTimeNow
  }
}
