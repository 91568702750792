import i18n from 'i18n'
const { t } = i18n

export enum ReportPeriod {
  ThisMonth,
  LastMonth,
  ThisWeek,
  LastWeek,
  Last31Days,
}

export enum SummarizeAt {
  OrganizationLevel = 'organization',
  AccountLevel = 'account'
}

export enum GeneratingStatus {
  Requested = 0,
  InProgress = 1,
  ErrorBeforeRetrying = 5,
  Completed = 7,
  ErrorAfterRetrying = 8,
  CompletedEmptyData = 10
}

export enum ReportFormat {
  pdf = 1,
  csv
}

export const periodOptions = [
  {
    value: ReportPeriod.ThisMonth,
    label: t('reporting_dashboard.new_backup_summary_report.options.this_month')
  },
  {
    value: ReportPeriod.LastMonth,
    label: t('reporting_dashboard.new_backup_summary_report.options.last_month')
  },
  {
    value: ReportPeriod.ThisWeek,
    label: t('reporting_dashboard.new_backup_summary_report.options.this_week')
  },
  {
    value: ReportPeriod.LastWeek,
    label: t('reporting_dashboard.new_backup_summary_report.options.last_week')
  },
  {
    value: ReportPeriod.Last31Days,
    label: t('reporting_dashboard.new_backup_summary_report.options.last_31_days')
  }
]

export const periodFilterOptions = [
  {
    value: 'all',
    label: t('reporting_dashboard.new_backup_summary_report.options.all')
  },
  ...periodOptions
]

export const productTypeOptions = [
  {
    value: 1,
    label: 'Email & Collaboration Backup'
  }
]

export const summarizeAtOptions = [
  {
    value: SummarizeAt.OrganizationLevel,
    label: t('reporting_dashboard.new_backup_summary_report.options.organization_level')
  },
  {
    value: SummarizeAt.AccountLevel,
    label: t('reporting_dashboard.new_backup_summary_report.options.account_level')
  }
]

export const summarizeAtFilterOptions = [
  {
    value: 'all',
    label: t('reporting_dashboard.new_backup_summary_report.options.all')
  },
  ...summarizeAtOptions
]

export const workloadOptions = [
  {
    value: 1,
    label: 'Account workload (Email, Drive, Contact, Calendar, Task, Private Chat for M365)'
  }
]

export const formatOptions = [
  {
    value: ReportFormat.pdf,
    label: t('reporting_dashboard.new_backup_summary_report.options.pdf')
  },
  {
    value: ReportFormat.csv,
    label: t('reporting_dashboard.new_backup_summary_report.options.csv')
  },
]

export const formatFilterOptions = [
  {
    value: 'all',
    label: t('reporting_dashboard.new_backup_summary_report.options.all')
  },
  ...formatOptions
]

export const statusOptions = [
  {
    value: 'all',
    label: t('reporting_dashboard.new_backup_summary_report.options.all')
  },
  {
    value: 'queued',
    label: t('reporting_dashboard.backup_summary_report.status_badge.queued')
  },
  {
    value: 'generating',
    label: t('reporting_dashboard.backup_summary_report.status_badge.generating')
  },
  {
    value: 'completed',
    label: t('reporting_dashboard.backup_summary_report.status_badge.completed')
  },
  {
    value: 'failed',
    label: t('reporting_dashboard.backup_summary_report.status_badge.failed')
  },
]

export const customBoxShadow = '0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06)'