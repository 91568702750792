import * as React from 'react'
import i18n from 'i18n'
import { NppCard, NppTypography, NppButton } from '@ui/Components'
import useMasterPartner from 'bundles/features/listPartnerDropdown/hooks/useMasterPartner'
import useBillingCard from '../hooks/useBillingCard'

const BillingCard = () => {
  const { t } = i18n
  const isMasterPartner = useMasterPartner()
  const { billingData, isLoading } = useBillingCard()

  if (!isMasterPartner) return null

  return (
    <NppCard
      alignButton="center"
      className={`ReportingDashboardBilling__card${isLoading ? '-loading' : ''}`}
      loading={isLoading}>
      <div className="ReportingDashboardBilling__card-left">
        <i className="line-bill-alt" />
        <div className="ReportingDashboardBilling__card-description">
          <NppTypography variant="subtitle1">
            {t('reporting_dashboard.index.billing_card.header')}
          </NppTypography>
          <NppTypography variant="h3">{`${billingData?.currency ?? '$'} ${
            billingData?.total_invoices ?? 0
          }`}</NppTypography>
          <NppTypography variant="caption">
            {billingData?.outstanding_invoice_count > 0
              ? t('reporting_dashboard.index.billing_card.description', {
                  outstanding: `${billingData?.currency ?? '$'}${billingData?.outstanding_invoice_count ?? 0}`,
                  date: billingData?.outstanding_invoice_date ?? ''
                })
              : t('reporting_dashboard.index.billing_card.description_no_outstanding')}
          </NppTypography>
        </div>
      </div>
      <NppButton variant="outlined" href="/billing">
        {t('reporting_dashboard.index.billing_card.billing_details_button')}
      </NppButton>
    </NppCard>
  )
}

export default BillingCard
