import * as React from 'react';
import { ReactNode } from 'react';
import { Grid, Skeleton, GridProps } from '@mui/material';

interface NppCardListItemProps extends GridProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  loading?: boolean;
  compact?: boolean;
}

const NppCardListItem: React.FC<NppCardListItemProps> = ({
  leftContent,
  rightContent,
  children,
  className,
  loading = false,
  compact = false,
  ...rest
}) => {
  const renderLeftContent = () => {
    if (!leftContent) return null;
    if (loading) {
      return (
        <Skeleton
          className="NppCardListItem__left-content"
          variant="circular"
          width={40}
          height={40}
        />
      );
    }

    return (
      <Grid item className="NppCardListItem__left-content">
        {leftContent}
      </Grid>
    );
  };

  const renderRightContent = () => {
    if (!rightContent) return null;

    return (
      <Grid item className="NppCardListItem__right-content">
        {rightContent}
      </Grid>
    );
  };

  const renderMainContent = () => {
    return (
      <Grid item className="NppCardListItem__content">
        {loading && (
          <>
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="10%" height={15} />
          </>
        )}

        {!loading && children}
      </Grid>
    );
  };

  const attributes: GridProps = {
    ...(loading ? { justifyContent: 'center', alignItems: 'center' } : {}),
  };

  return (
    <Grid
      container
      className={`NppCardListItem__wrapper ${loading ? 'NppCardListItem__loading' : ''} ${
        compact ? 'NppCardListItem__compact-padding' : ''
      } ${className}`}
      {...attributes}
      {...rest}
    >
      {renderLeftContent()}
      {renderMainContent()}
      {renderRightContent()}
    </Grid>
  );
};

export default NppCardListItem;

NppCardListItem.defaultProps = {
  leftContent: null,
  rightContent: null,
  className: '',
  children: '',
  loading: false,
  compact: false,
};
