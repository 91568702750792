import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getProtectionCoverageReportApi } from '@services/ApiServices'
import BaseSetting from '@config/BaseSetting'

interface GetProtectionCoverageReportApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

// Specify the payload type explicitly here
interface PayloadInterface {
  page: number
  sort_column: number
  sort_direction: string
  partner_ids: string[]
  page_size: number
  page_action: string
  pagination_state?: string
}

const getProtectionCoverageReportAsync = createAsyncThunk(
  'ProtectionCoverageReport/getProtectionCoverageReport',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getProtectionCoverageReportApi({ ...payload, csrfToken: BaseSetting.csrfToken })
    return data as GetProtectionCoverageReportApiData // Adjust the type accordingly
  }
)

const resetProtectionCoverageReport = createAction('ProtectionCoverageReport/resetProtectionCoverageReport')

const getProtectionCoverageReportExtraReducers = (builder: any) => {
  builder
    .addCase(getProtectionCoverageReportAsync.pending, (state) => {
      state.getProtectionCoverageReport = true
    })
    .addCase(getProtectionCoverageReportAsync.rejected, (state) => {
      state.getProtectionCoverageReport = false
    })
    .addCase(getProtectionCoverageReportAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.protectionCoverageReport = action.payload.data
      } else {
        state.error = action.payload.error
      }
      state.getProtectionCoverageReport = false
    })
    .addCase(resetProtectionCoverageReport, (state) => {
      state.protectionCoverageReport = {}
      state.getProtectionCoverageReport = false
      state.error = null
    })
}

export {
  getProtectionCoverageReportAsync,
  getProtectionCoverageReportExtraReducers,
  resetProtectionCoverageReport
}
