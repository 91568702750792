import { useEffect, useState } from "react"
import { useGetProtectionCoverageQuery } from "../services/reportingDashboardApi"
import { useAppDispatch, useAppSelector } from "bundles/hooks"
import { numberToPercentage } from "bundles/utilities"
import { setLoadingStatus } from ".."

const useProtectionCoverageChart = () => {
  const dispatch = useAppDispatch()
  const { partnerIds } = useAppSelector((state) => state.listPartnerDropdown)
  const { data, isFetching, isSuccess } = useGetProtectionCoverageQuery({ partner_ids: partnerIds });
  const [ chartData, setChartData ] = useState({ 
    values: 0, 
    colors: ['#B9262E', '#DD252F', '#36B252'], 
    colorThresholds: [50, 80, 100]
  })

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(chartData => ({
        ...chartData,
        values: numberToPercentage(
          data?.protected_account_count,
          data?.total_account_count
        ) ?? 0
      }));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    dispatch(setLoadingStatus({ getProtectionCoverage: isFetching }))
  }, [isFetching])

  return {
    data,
    chartData,
    isFetching
  }
}

export default useProtectionCoverageChart