import * as React from 'react'
import * as dayjs from 'dayjs'
import { Stack, Typography, Link } from '@mui/material'
import { SmallDownloadButton } from '../components'
import { OrganizationIcon, AccountIcon } from 'bundles/ui/SvgIcons'
import { SummarizeAt, ReportPeriod } from 'bundles/ui/SharedConstants'
import { StatusBadge, DownloadLinks } from '../components'
import { convertReportPeriodToFirstCase } from 'bundles/ui/Helpers'
import i18n from 'i18n'

const { t } = i18n

export const columns = (onClick, onClickLink) => [
  {
    field: 'created_at',
    headerName: t('reporting_dashboard.backup_summary_report.requested_at'),
    minWidth: 140,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyTitle">{dayjs(params.row.created_at).format('DD MMM YYYY')}</Typography>
          <Typography variant="nppOverline">{dayjs(params.row.created_at).format('hh:mm A')}</Typography>
        </Stack>
      )
    }
  },
  {
    field: 'title',
    headerName: t('reporting_dashboard.backup_summary_report.report_info'),
    minWidth: 240,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Link underline="none" onClick={() => onClickLink(params.row.id)}>
            <Typography variant="nppBodyTitle" sx={{ color: 'npp.blue.50' }}>
              {params.row.title}
            </Typography>
          </Link>
          <Typography variant="nppOverline">{params.row.description || 'No description'}</Typography>
          <Typography variant="nppOverline">{`ID: ${params.row.id}-${params.row.region_id}`}</Typography>
        </Stack>
      )
    }
  },
  {
    field: 'summary_level',
    headerName: t('reporting_dashboard.backup_summary_report.summarize_at'),
    minWidth: 180,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
            {params.row.summary_level === SummarizeAt.OrganizationLevel && (
              <OrganizationIcon
                sx={{
                  fontSize: 12
                }}
              />
            )}
            {params.row.summary_level === SummarizeAt.AccountLevel && (
              <AccountIcon
                sx={{
                  fontSize: 12
                }}
              />
            )}
            <Typography
              variant="nppBodyTitle"
              sx={{ textTransform: 'capitalize' }}>{`${params.row.summary_level} level`}</Typography>
          </Stack>
        </Stack>
      )
    }
  },
  {
    field: 'period_start',
    headerName: t('reporting_dashboard.backup_summary_report.report_period'),
    minWidth: 194,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyTitle" sx={{ textTransform: 'capitalize' }}>
            {convertReportPeriodToFirstCase(ReportPeriod[params.row.report_period])}
          </Typography>
          <Typography variant="nppOverline">{`${dayjs(params.row.period_start).format('DD MMM YYYY')} - ${dayjs(
            params.row.period_end
          ).format('DD MMM YYYY')}`}</Typography>
        </Stack>
      )
    }
  },
  {
    field: 'account_id',
    headerName: t('reporting_dashboard.backup_summary_report.requested_by'),
    minWidth: 160,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyTitle">{params.row.account_name}</Typography>
          <Typography noWrap variant="nppOverline">
            {params.row.account_email}
          </Typography>
        </Stack>
      )
    }
  },
  {
    field: 'download_links',
    headerName: t('reporting_dashboard.backup_summary_report.download_links'),
    minWidth: 165,
    renderCell: (params) => {
      return (
        <DownloadLinks
          id={params.row.id}
          status={params.row.status}
          onClick={() => onClick(params.row.id)}
          partFinished={params.row.part_finished}
          partTotal={params.row.part_total}
          isInTable={true}
          bsrItemExists={params.row.bsr_items_exists}
        />
      )
    }
  },
  {
    field: 'status',
    headerName: t('reporting_dashboard.backup_summary_report.filter.status'),
    minWidth: 140,
    renderCell: (params) => {
      return <StatusBadge status={params.row.status} />
    }
  }
]

export const columnsBsrRequestItem = [
  {
    field: 'id',
    minWidth: 260,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppOverline">{`Part ${params.row.index}`}</Typography>
          <Typography variant="nppBodyText">{params.row.title}</Typography>
        </Stack>
      )
    }
  },
  {
    width: 84,
    field: 'file_path',
    renderCell: (params) => <SmallDownloadButton {...params.row} />
  }
]

export const columnsAccount = [
  {
    field: 'account_uid',
    headerName: 'ID',
    minWidth: 96,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyText">{params.row.account_uid}</Typography>
        </Stack>
      )
    }
  },
  {
    field: 'account_email',
    headerName: t('reporting_dashboard.backup_summary_report.name'),
    minWidth: 240,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyText" noWrap>
            {params.row.account_email}
          </Typography>
        </Stack>
      )
    }
  }
]

export const columnsOrganization = [
  {
    field: 'organization_id',
    headerName: 'ID',
    minWidth: 96,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyText">{params.row.organization_id}</Typography>
        </Stack>
      )
    }
  },
  {
    field: 'organization_name',
    headerName: t('reporting_dashboard.backup_summary_report.name'),
    minWidth: 240,
    renderCell: (params) => {
      return (
        <Stack justifyContent={'center'} sx={{ height: '100%' }}>
          <Typography variant="nppBodyText" noWrap>
            {params.row.organization_name}
          </Typography>
        </Stack>
      )
    }
  }
]
