import { useEffect } from 'react'
import { useAppDispatch } from '@hooks/index'
import { usePageAlert, useHtmlPageAlert } from '@hooks/index'


export default function useCreatePartnerAlert() {
  const dispatch = useAppDispatch()
  const { payload: payloadAlert } = useHtmlPageAlert()
  const { updateSuccessAlert } = usePageAlert()

  useEffect(() => {
    if (
      payloadAlert ||
      payloadAlert?.type ||
      payloadAlert?.message
    ) {
      updateSuccessAlert(payloadAlert)
    }
  }, [dispatch])
}
