import { useContext } from 'react'
import PropsContext from '@contexts/PropsContext'

const usePropsContext = () => {
  const props = useContext(PropsContext)

  const pageAlertHtml = !(props?.pageAlertHtml === '<div class="page-alert"></div>' || props?.pageAlertHtml?.includes('hidden')) ? props?.pageAlertHtml : null
  const pageBannerHtml = !(props?.pageBannerHtml === '<div class="page-banner"></div>' || props?.pageBannerHtml?.includes('hidden')) ? props?.pageBannerHtml : null

  return {
    pageAlertHtml,
    pageBannerHtml
  }
}

export default usePropsContext
