// import BaseSetting from "@config/BaseSetting";
import Routes from './Routes'

/**
 * Contains web api urls
 * */

class WebClientRoutes extends Routes {
  rootPath(params = {}, format = '') {
    return this.createUrl('/', params, format)
  }
  reportingDashboardPath(params = {}, format = '') {
    return this.createUrl('/dashboard', params, format)
  }
  protectionCoverageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/protection-coverage-report', params, format)
  }
  seatUsageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/seat-usage-report', params, format)
  }
  organizationsPath(params = {}, format = '') {
    return this.createUrl('/organizations_dashboard', params, format)
  }
  accountsPath(params = {}, format = '') {
    return this.createUrl('/accounts_dashboard', params, format)
  }
  supportPath(params = {}, format = '') {
    return this.createUrl('/support/overview', params, format)
  }
  createReportsBackupSummaryPath(params = {}, format = '') {
    return this.createUrl('/reports/backup_summary_report/new', params, format)
  }
  reportsBackupSummaryPath(params = {}, format = '') {
    return this.createUrl('/reports/backup_summary_report', params, format)
  }
}

const ClientRoutes = new WebClientRoutes()

export const rootPath = ClientRoutes.rootPath()
export const reportingDashboardPath = ClientRoutes.reportingDashboardPath()
export const protectionCoverageReportPath = ClientRoutes.protectionCoverageReportPath()
export const seatUsageReportPath = ClientRoutes.seatUsageReportPath()
export const organizationsPath = ClientRoutes.organizationsPath()
export const accountsPath = ClientRoutes.accountsPath()
export const supportPath = ClientRoutes.supportPath()
export const reportsBackupSummaryPath = ClientRoutes.reportsBackupSummaryPath()
export const createReportsBackupSummaryPath = ClientRoutes.createReportsBackupSummaryPath()

export default ClientRoutes
