import * as React from 'react';
import { useState, ReactNode } from 'react';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface NppTextFieldProps {
  id?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  helperText?: string;
  fullWidth?: boolean;
  withoutErrorIcon?: boolean;
  isPasswordField?: boolean;
  className?: string,
  [key: string]: any;
}

const NppTextField: React.FC<NppTextFieldProps> = ({
  id = '',
  label = null,
  name = '',
  disabled = false,
  errorMessage = null,
  placeholder = null,
  startIcon = null,
  endIcon = null,
  helperText = null,
  fullWidth = false,
  withoutErrorIcon = false,
  isPasswordField = false,
  className = '',
  ...rest
}) => {
  const inputAttributes: { [key: string]: any } = {};
  let error = false;

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  if (name) inputAttributes.name = name;
  if (placeholder) inputAttributes.placeholder = placeholder;
  if (errorMessage) error = true;

  if (startIcon) {
    inputAttributes.startAdornment = <InputAdornment position="start">{startIcon}</InputAdornment>;
  }

  if (endIcon) {
    inputAttributes.endAdornment = <InputAdornment position="end">{endIcon}</InputAdornment>;
  }

  if (isPasswordField) {
    if (isPasswordVisible) {
      inputAttributes.type = 'text';
      inputAttributes.endAdornment = (
        <InputAdornment
          position="end"
          className="NppTextField__password-visible-icon"
          onClick={() => setPasswordVisible(false)}>
          <VisibilityOffOutlinedIcon />
        </InputAdornment>
      );
    } else {
      inputAttributes.type = 'password';
      inputAttributes.endAdornment = (
        <InputAdornment
          position="end"
          className="NppTextField__password-visible-icon"
          onClick={() => setPasswordVisible(true)}>
          <RemoveRedEyeOutlinedIcon />
        </InputAdornment>
      );
    }
  }

  if (rest?.endAdornment) {
    inputAttributes.endAdornment = rest.endAdornment;
  }

  return (
    <FormControl
      className={`FormControl__default-wrapper ${
        fullWidth ? 'FormControl__full-width-wrapper' : ''
      } ${className}`}
      disabled={disabled}
      error={error}>
      {label && (
        <label className="NppTextField__label" htmlFor={id}>
          {label}
        </label>
      )}

      <InputBase id={id} {...inputAttributes} {...rest} onKeyDown={(e) => e.stopPropagation()} />

      {helperText && !errorMessage && (
        <span className="NppTextField__helper-text">{helperText}</span>
      )}

      {errorMessage && (
        <span className="NppTextField__error-text">
          {!withoutErrorIcon && <ErrorIcon />}
          {errorMessage}
        </span>
      )}
    </FormControl>
  );
};

export default NppTextField;
