import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { downloadCSVReportSeatUsageApi } from '@services/ApiServices'

interface DownloadCSVReportApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

// Specify the payload type explicitly here
interface PayloadInterface {
  criteria: string
  file_type: string
}

const downloadCSVReportAsync = createAsyncThunk(
  'SeatUsageReport/downloadCSVReport',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await downloadCSVReportSeatUsageApi({ ...payload })
    return data as DownloadCSVReportApiData // Adjust the type accordingly
  }
)

const resetDownloadCSVReport = createAction('SeatUsageReport/resetDownloadCSVReport')

const downloadCSVReportExtraReducers = (builder: any) => {
  builder
    .addCase(downloadCSVReportAsync.pending, (state) => {
      state.downloadCSVReport.submitting = true
    })
    .addCase(downloadCSVReportAsync.rejected, (state) => {
      state.downloadCSVReport.submitting = false
    })
    .addCase(downloadCSVReportAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.downloadCSVReport.success = true
      } else {
        state.downloadCSVReport.error = action.payload.error
      }
      state.downloadCSVReport.submitting = false
    })
    .addCase(resetDownloadCSVReport, (state) => {
      state.downloadCSVReport.error = {}
      state.downloadCSVReport.success = false
      state.downloadCSVReport.submitting = null
    })
}

export {
  downloadCSVReportAsync,
  downloadCSVReportExtraReducers,
  resetDownloadCSVReport
}
