/* eslint-disable prefer-regex-literals */

export const stringRandom = (length = 7) => {
  // program to generate random strings
  // declare all characters
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const noSpace = (string) => {
  if (typeof string === 'string') {
    return string.replace(' ', '');
  }
  return '';
};

export const childrenToString = (children) => {
  if (!children) return '';

  return children
    .toString()
    .replaceAll('[object Object]', '')
    .replace(/(^,)|(,$)/g, '')
    .trim();
};

/* eslint-disable no-console */
export const pascalToTitleCase = (string) => {
  if (!string) return '';

  try {
    // insert a space between lower & upper
    // space before last upper in a sequence followed by lower
    // uppercase the first character
    return string
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
      .trim();
  } catch (err) {
    console.info(err.message);
    return string;
  }
};

/* eslint-disable no-console */
export const stringToPascalCase = (text) => {
  try {
    const result = text.replace(/([A-Z])/g, ' $1');
    return (result.charAt(0).toUpperCase() + result.slice(1)).replaceAll(' ', '');
  } catch (err) {
    console.info(err.message);
    return text;
  }
};

export const trim = (value = '') => {
  if (!value) return '';
  if (typeof value !== 'string') return '';
  return value.trim();
};

// When value not containt format
// will be considered as kilobytes (KB)
export const convertSize = (paramsBytes, size = 'GB', decimals = 2) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const splittedParams = typeof paramsBytes === 'string' ? paramsBytes?.split(/(\d+)/) : [];
  const valueToFormat = splittedParams[2] ? splittedParams[1] : paramsBytes;
  const fromSize = splittedParams[2] || 'KB';

  if (!+valueToFormat) return '0Bytes';
  if (!sizes.includes(fromSize)) return '0Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const bytes = valueToFormat * k ** sizes.indexOf(fromSize);

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const result = size
    ? `${parseFloat((bytes / k ** sizes.indexOf(size)).toFixed(dm))}${sizes[sizes.indexOf(size)]}`
    : `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;

  return result;
};

//Convert string to number
export const parseNumber = (value: string = '') => {
  if (!value) return 0;
  if (typeof value !== 'string') return 0;
  return Number(value);
};
