import * as React from 'react'
import { NppTypography, NppDropdown, NppTextField, NppButton } from '@ui/Components'
import { useOrganizationDropdown } from ".."
import BaseSetting from "bundles/config/BaseSetting"
import { Checkbox } from "@mui/material"
import i18n from 'i18n'
import { useEffect } from 'react'
import { bgIconInfo } from '@config/themes/default/variables'

const OrganizationDropdownFilter = ({ filterByOrganization }) => {
  const { t } = i18n
  const {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    onFilterDropdown,
    onSelectItem,
    listOrganizationSearched,
    listOrganizationDefault,
    totalSelectedText, 
    selectedIds
  } = useOrganizationDropdown()

  useEffect(() => {
    filterByOrganization(selectedIds)
  }, [selectedIds])

  const getListOptions = () => {
    const lists = searchValue?.length >= BaseSetting.allowTypeRequestLength ? listOrganizationSearched : listOrganizationDefault
    const listOptions = lists?.map((item) => {
      return [
        item,
        <div className="FilterDropdownBlock__wrapper">
          <Checkbox checked={selected.includes('all') !== selected.includes(item.id)} />
          <NppTypography className="FilterDropdownBlock__item-list" variant="subtitle1">
            {item?.organization_name}
          </NppTypography>
        </div>
      ]
    })
    return listOptions
  }

  return (
    <NppDropdown
      variant="checkbox"
      menuItems={getListOptions()}
      onClickItem={(item: { organization_name?: string | number; id?: string | number }) => {
        onSelectItem(item?.id)
      }}
      headerMenu={
        <div className="FilterDropdownBlock__header">
          <NppTextField
            className="FilterDropdownBlock__search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.currentTarget.value)
            }}
            fullWidth
            endIcon={<i className="line-search" />}
          />
          <div className="FilterDropdownBlock__checkbox-header">
            <Checkbox
              checked={selected?.includes('all')}
              onClick={() => {
                setSelected(selected?.includes('all') ? [] : ['all'])
              }}
            />
            <NppTypography className="FilterDropdownBlock__item-list" variant="subtitle1">
              {t('reporting_dashboard.organization_dropdown.select_all')}
            </NppTypography>
          </div>
        </div>
      }
      footerMenu={({ handleMenuClose }) => (
        <div className="FilterDropdownBlock__footer">
          <div className="FilterDropdownBlock__line" />
          <NppButton
            className="FilterDropdownBlock_Button"
            variant="contained"
            onClick={() => {
              onFilterDropdown()
              handleMenuClose()
            }}>
            {t('reporting_dashboard.organization_dropdown.filter_button')}
          </NppButton>
        </div>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      className="NppDropdown__button"
      sx={{border: 1, borderColor: bgIconInfo, color: bgIconInfo}}
      iconClassName={"organizationfilter__icon"}>
      <NppTypography variant="subtitle1" sx={{color: bgIconInfo}}>{totalSelectedText}</NppTypography>
    </NppDropdown>
  )
}

export default OrganizationDropdownFilter