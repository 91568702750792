import * as React from 'react'
import i18n from 'i18n'
import InfoCardBlock from '@ui/Blocks/Shared/CardBlock/InfoCardBlock'
import { numberToPercentage } from '@utilities/index'

interface InfoCardSectionProps {
  protectionCoverageData: {
    protected_account_count: number
    total_account_count: number
    unprotected_account_count: string | number
  }
}

const InfoCardSection = ({ protectionCoverageData}: InfoCardSectionProps) => {
  const { t } = i18n

  return (
    <div className="ProtectionCoverageReport__info-wrapper">
      <InfoCardBlock
        icon={<i className="solid-padlocked-shield" />}
        title={`${numberToPercentage(
          protectionCoverageData?.protected_account_count,
          protectionCoverageData?.total_account_count
        )}%`}
        subtitle={` ${t('reporting_dashboard.protection_coverage_report.card.title')}`}
        description={t('reporting_dashboard.protection_coverage_report.card.description', {
          accounts_backed_up: protectionCoverageData?.protected_account_count ?? 0,
          total_accounts: protectionCoverageData?.total_account_count ?? 0
        })}
      />
      <InfoCardBlock
        icon={<i className="solid-padlock-shield" />}
        title={protectionCoverageData?.unprotected_account_count ?? 0}
        description={t('reporting_dashboard.protection_coverage_report.card.description_2')}
      />
    </div>
  )
}

export default InfoCardSection
