import * as React from 'react'
import i18n from 'i18n'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import {
  getProtectionCoverageReportAsync,
  resetProtectionCoverageReport,
  downloadCSVReportAsync,
  resetDownloadCSVReport
} from '@features/protectionCoverageReport/protectionCoverageReportSlice'
import {
  getProtectionCoverageAsync,
  resetProtectionCoverage
} from '@features/reportingDashboard/reportingDashboardSlice'
import { getPartnerTypeAsync, resetPartnerType } from '@features/application/applicationSlice'
import { NppTypography, NppLink } from '@ui/Components'
import { numberToPercentage, roundNumber } from '@utilities/index'
import { usePageNumber, useAlertMessage, usePageAlert } from '@hooks/index'
import { TableColumn, TableRowData } from '@ui/Components/NppSimpleDataTable'

interface TableHeader extends TableColumn {}

interface TableRow extends TableRowData {
  [key: string]: string | number | boolean | React.ReactNode
}

const defaultOrder = 'asc'
const defaultOrderColumn = 'protectionCoverage'

const idColumn = 'id'
const organizationNameColumn = 'organizationName'
const partnerNameColumn = 'partnerName'
const unprotectedLicensedAccountColumn = 'unprotectedLicensedAccount'
const unprotectedAccountColumn = 'unprotectedAccount'
const protectedAccountColumn = 'protectedAccount'
const totalAccountColumn = 'totalAccount'
const protectionCoverageColumn = 'protectionCoverage'

const partnerTypePartner = 'Partner'

const listColumn = [
  idColumn,
  organizationNameColumn,
  partnerNameColumn,
  unprotectedLicensedAccountColumn,
  unprotectedAccountColumn,
  protectedAccountColumn,
  totalAccountColumn,
  protectionCoverageColumn
]

const colorList = ['#B9262E', '#DD252F', '#36B252']

const getColorPercentage = (value: number): string => {
  if (value < 50) return colorList[0]
  if (value < 80) return colorList[1]
  if (value <= 100) return colorList[2]
  return colorList[0]
}

const createHeader = (
  id: number,
  label: string,
  columnName: string,
  orderable: boolean,
  size: string,
  align: string,
  tooltipText: string = ''
): TableHeader => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: false,
    align: 'left',
    tooltipText,
    wrap: true
  }
}

const createData = (
  id: number | string,
  organizationName: string,
  partner: string,
  unprotectedLicensedAccount: number,
  unprotectedAccount: number,
  protectedAccount: number,
  totalAccount: number,
  protectionCoverage: number,
  organizationId?: number | string,
  regionId?: number | string
): TableRow => {
  return {
    id: `${organizationId}-${regionId}`,
    organizationName: (
      <NppLink href={`/organizations/${organizationId}-${regionId}/subscriptions`} underline="hover" withTitle>
        {organizationName}
      </NppLink>
    ),
    partnerName: (
      <NppTypography color="mute" noWrap title={partner}>
        {partner}
      </NppTypography>
    ),
    unprotectedLicensedAccount: (
      <NppTypography color="mute" noWrap>
        {unprotectedLicensedAccount}
      </NppTypography>
    ),
    unprotectedAccount: (
      <NppTypography color="mute" noWrap>
        {unprotectedAccount}
      </NppTypography>
    ),
    protectedAccount: (
      <NppTypography color="mute" noWrap>
        {protectedAccount}
      </NppTypography>
    ),
    totalAccount: (
      <NppTypography color="mute" noWrap>
        {totalAccount}
      </NppTypography>
    ),
    protectionCoverage: (
      <NppTypography color={getColorPercentage(protectionCoverage) ?? colorList[0]} noWrap>
        {protectionCoverage}%
      </NppTypography>
    )
  }
}

export default function useFetchReportingDashboardWidget({ partnerIds = [] }) {
  const { t } = i18n
  const prefixTranslation = 'reporting_dashboard.protection_coverage_report.table.column'
  const dispatch = useAppDispatch()
  const { getSuccessMessage, konstMessage } = useAlertMessage()
  const { updateSuccessAlert } = usePageAlert()
  const { protectionCoverageReport, getProtectionCoverageReport } = useAppSelector(
    (state) => state.protectionCoverageReport
  )
  const { protectionCoverage, getProtectionCoverage } = useAppSelector((state) => state.reportingDashboard)
  const { partnerType, getPartnerType } = useAppSelector((state) => state.application)

  const [order, setOrder] = useState<'asc' | 'desc'>(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderColumn)
  const [page, setPage] = useState(1)
  const [pageNumber, setPageNumber] = usePageNumber()
  const [showModalCsv, setShowModalCsv] = useState<boolean>(false)
  const [pageAction, setPageAction] = useState(null)

  const tableHeaders = [
    createHeader(1, t(`${prefixTranslation}.id`), idColumn, false, 'auto', 'left'),
    createHeader(2, t(`${prefixTranslation}.organization_name`), organizationNameColumn, false, '15.143rem', 'left'),
    createHeader(3, t(`${prefixTranslation}.partner_name`), partnerNameColumn, false, '14.286rem', 'left'),
    createHeader(
      4,
      t(`${prefixTranslation}.protected_account`),
      unprotectedLicensedAccountColumn,
      false,
      '9.286rem',
      'left',
      t(`${prefixTranslation}.protected_account_tooltip`)
    ),
    createHeader(
      5,
      t(`${prefixTranslation}.unprotected_billable_account`),
      unprotectedAccountColumn,
      false,
      '14.286rem',
      'left',
      t(`${prefixTranslation}.unprotected_billable_account_tooltip`)
    ),
    createHeader(
      6,
      t(`${prefixTranslation}.total_unprotected_account`),
      protectedAccountColumn,
      false,
      '12.857rem',
      'left',
      t(`${prefixTranslation}.total_unprotected_account_tooltip`)
    ),
    createHeader(7, t(`${prefixTranslation}.total_account`), totalAccountColumn, false, '9.286rem', 'left', t(`${prefixTranslation}.total_account_tooltip`)),
    createHeader(
      8,
      t(`${prefixTranslation}.protection_coverage`),
      protectionCoverageColumn,
      false,
      '10.714rem',
      'left',
      t(`${prefixTranslation}.protection_coverage_tooltip`)
    )
  ]

  const tableRows = protectionCoverageReport?.data?.map((item) =>
    createData(
      item?.id,
      item?.organization_name,
      item?.reseller_name,
      item?.protected_account_count,
      item?.unprotected_account_count,
      item?.total_unprotected_account_count,
      item?.total_account_count,
      item?.protection_coverage,
      item?.organization_id,
      item?.region_id
    )
  )

  useEffect(() => {
    const payload = {
      page,
      sort_column: listColumn.indexOf(orderBy),
      sort_direction: order,
      partner_ids: partnerIds,
      page_size: pageNumber,
      page_action: pageAction
    }

    if (protectionCoverageReport?.pagination_state && page > 1) {
      payload['pagination_state'] = JSON.stringify(protectionCoverageReport?.pagination_state)
    }

    dispatch(getProtectionCoverageReportAsync(payload))

    return () => {
      dispatch(resetProtectionCoverageReport())
    }
  }, [dispatch, page, pageNumber, order, partnerIds]) // Include `dispatch` in the dependency array

  useEffect(() => {
    dispatch(getProtectionCoverageAsync({ partner_ids: partnerIds }))

    return () => {
      dispatch(resetProtectionCoverage())
    }
  }, [dispatch, partnerIds])

  useEffect(() => {
    dispatch(getPartnerTypeAsync())

    return () => {
      dispatch(resetPartnerType())
    }
  }, [dispatch])

  const hasPrevPage = (): boolean => {
    return page != 1
  }

  const hasNextPage = (): boolean => {
    return (
      protectionCoverageReport?.is_last_page !== true
    )
  }

  const onPrevPage = () => {
    setPageAction('prev')
    if (hasPrevPage) setPage(page - 1)
  }

  const onNextPage = () => {
    setPageAction('next')
    if (hasNextPage) setPage(page + 1)
  }

  const onChangePageNumber = (number) => {
    setPage(1)
    setPageAction(null)
    setPageNumber(number)
  }
  
  const handleDownloadCsv = () => {
    const criteria = {
      filters: { partner: partnerIds?.length === 0 ? 'All' : partnerIds },
      status: { generating: false },
      page: 'protection-coverage-report',
      url: '/dashboard/protection-coverage-report'
    }

    const payload = {
      criteria: JSON.stringify(criteria),
      file_type: 'csv'
    }
    dispatch(downloadCSVReportAsync(payload)).then(() => {
      dispatch(resetDownloadCSVReport())
    })
  }

  const onClickConfirmationModal = () => {
    handleDownloadCsv()
    setShowModalCsv(false)
    const alertPayload = {
      ...getSuccessMessage(konstMessage.SUCCESS_GENERATE_CSV, {})
    }

    updateSuccessAlert(alertPayload)
  }

  return {
    isLoading: getProtectionCoverageReport && getProtectionCoverage && getPartnerType,
    protectionCoverageData: protectionCoverage,
    protectionCoverageReportData: protectionCoverageReport,
    tableHeaders,
    tableRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    pageNumber,
    setPageNumber,
    hasPrevPage: hasPrevPage(),
    hasNextPage: hasNextPage(),
    onPrevPage,
    onNextPage,
    onChangePageNumber: onChangePageNumber,
    isMasterPartner: partnerType?.partner_type === partnerTypePartner,
    showModal: showModalCsv,
    onOpenModal: () => setShowModalCsv(true),
    onCloseModal: () => setShowModalCsv(false),
    onClickConfirmationModal
  }
}
