import * as React from 'react'
import WidgetCardBlock from 'bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock';
import { OrganizationDropdownFilter } from 'bundles/features/listOrganizationDropdown';
import i18n from 'i18n'
import useJobStatusChart from '../hooks/useJobStatusChart';
import useMasterPartner from 'bundles/features/listPartnerDropdown/hooks/useMasterPartner';
import { supportPath } from 'bundles/config/Routes/WebClientRoutes';
import { useOrganizationDropdown } from 'bundles/features/listOrganizationDropdown';
import usePartnerDropdown from 'bundles/pages/sharedHooks/usePartnerDropdown';

const JobStatusChart = ({}) => {
  const { t } = i18n
  const { data, chartData, isFetching, filterByOrganization, totalPartner, totalOrganization } = useJobStatusChart()
  const isMasterPartner = useMasterPartner()
  const { listOrganizationDefault } = useOrganizationDropdown()
  const { listPartnerDefault } = usePartnerDropdown()

  return (
    <WidgetCardBlock
      title={t('reporting_dashboard.index.backup_job_status_card.header')}
      description={'in last 24 hours'}
      tooltipTitle={t('reporting_dashboard.index.backup_job_status_card.info_header')}
      button={t('reporting_dashboard.index.backup_job_status_card.report_detail_button')}
      actionButton={supportPath}
      isLoading={isFetching}
      cardInfo={t(
        `reporting_dashboard.index.backup_job_status_card.description${
          !isMasterPartner ? '_without_partner' : ''
        }_html`,
        {
          organizations: totalOrganization === 0 ? listOrganizationDefault.length : totalOrganization,
          partners: totalPartner  === 0 ? listPartnerDefault.length : totalPartner
        }
      )}
      chartData={chartData}
      chartType="donut"
      chartTitle={t('reporting_dashboard.index.backup_job_status_card.chart_label')}
      chartId="backup-job-status-chart"
      showValueChart
      rightHeader={<OrganizationDropdownFilter filterByOrganization={filterByOrganization} />}
      
    />
  );
}

export default JobStatusChart