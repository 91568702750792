import { useEffect, useState } from 'react'
import i18n from 'i18n'
import { pushSpliceEntry, filterArrayByValue, filterArrayByKey, sliceArrayWithArray } from '@utilities/index'
import { useLocalStorage, useSearchDelay } from '@hooks/index'
import { constant } from '@config/BaseSetting'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import { setOrganizationIds } from '../slices/organizationDropdownSlice'
import { useGetListOrganizationDropdownQuery } from '..'
import usePartnerDropdown from 'bundles/pages/sharedHooks/usePartnerDropdown'

export default function useOrganizationDropdown() {
  const { t } = i18n
  const dispatch = useAppDispatch()
  const { submitOrganization } = useAppSelector((state) => state.listOrganizationDropdown)
  const { data: listOrganizationDropdown, isSuccess, refetch } = useGetListOrganizationDropdownQuery(undefined)
  const { success } = submitOrganization
  const { setSelectedPartnerSuccess } = usePartnerDropdown()

  //Active organization filter from local storage
  const [activeOrganizationFilter, setActiveOrganizationFilter] = useLocalStorage<any[]>(constant.ORGANIZATION_FILTER_STORAGE)
  const [selectedOrganizationFilter, setSelectedOrganizationFilter] = useState(activeOrganizationFilter ?? [])
  const [selected, setSelected] = useState(activeOrganizationFilter ?? [])
  // Selected Ids that will be send as parameter
  const [selectedIds, setSelectedIds] = useState([])
  // Sea value
  const [searchValue, setSearchValue] = useState('')
  // List All organization
  const [listOrganizationDefault, setListOrganizationDefault] = useState(listOrganizationDropdown ?? [])
  // List organization after searched
  const [listOrganizationSearched, setListOrganizationSearched] = useState([])

  useEffect(() => {
    if (setSelectedPartnerSuccess) refetch()
  }, [setSelectedPartnerSuccess])

  const getTotalSelected = (): number => {
    const selectedCount = selectedOrganizationFilter?.length ?? 0
    const listCount = listOrganizationDefault?.length ?? 0

    if (selectedOrganizationFilter?.includes('all')) {
      return selectedCount > 1 ? listCount - (selectedCount - 1) : listCount
    }

    return selectedCount
  }

  const getTotalSelectedText = (): string => {
    const totalSelected = getTotalSelected()
    const text: string =
      totalSelected > 0 && totalSelected !== listOrganizationDefault?.length
        ? t('reporting_dashboard.organization_dropdown.organization_selected', {
            selected: totalSelected
          })
        : t('reporting_dashboard.organization_dropdown.all_organization')

    return text
  }

  const onSearchDropdown = (value): any => {
    const filteredDropdown = filterArrayByValue(listOrganizationDefault, value, 'organization_name')
    if (filteredDropdown) {
      setListOrganizationSearched(filteredDropdown)
    }
  }

  const onSetSelectedIds = (ids, setSelectedOrganization = false) => {
    const isAllSelected = ids?.includes('all')

    // Prepare a base list of IDs from listOrganizationDefault if 'all' is selected
    let baseIds = isAllSelected ? filterArrayByKey(listOrganizationDefault, 'id') : []

    // If 'all' is selected and there are additional selected items,
    // remove the extra selected items from the base list of IDs
    if (isAllSelected && ids?.length > 1) {
      baseIds = sliceArrayWithArray(baseIds, ids)
    }

    // Set the selected IDs based on the above conditions
    // If 'all' is not selected, just use the currently selected IDs
    setSelectedIds(isAllSelected ? baseIds : ids)
    dispatch(setOrganizationIds(isAllSelected ? baseIds : ids))
  }

  const onFilterDropdown = () => {
    setSelectedOrganizationFilter(selected)
    setActiveOrganizationFilter(selected)
    onSetSelectedIds(selected, true)
  }

  const onSelectItem = (id) => {
    const selectedData = pushSpliceEntry(selected, id)
    const isSelectingAll = !selectedData?.includes('all') && selectedData?.length === listOrganizationDefault?.length
    const isDeselectingAll =
      selectedData?.includes('all') && selectedData?.length === listOrganizationDefault?.length + 1

    const newSelectedState = isSelectingAll ? ['all'] : isDeselectingAll ? [] : selectedData

    setSelected(newSelectedState)
  }


  useEffect(() => {
    const activeOrganizations = filterArrayByValue(listOrganizationDropdown, true, 'selected')
    const isSelectingAll = activeOrganizations.length === listOrganizationDropdown?.length
    const filterResult = isSelectingAll ? ['all'] : filterArrayByKey(activeOrganizations, 'id')

    setSelected(filterResult)
    setSelectedOrganizationFilter(filterResult)
    setActiveOrganizationFilter(filterResult)
    onSetSelectedIds(filterResult)
    setListOrganizationDefault(listOrganizationDropdown ?? [])
  }, [listOrganizationDropdown])

  useSearchDelay(
    {
      actionFunc: () => {
        onSearchDropdown(searchValue)
      },
      resetFunc: () => {
        setSearchValue('')
      },
      onChangeKeyword: () => {
        setSearchValue(searchValue)
      }
    },
    searchValue
  )

  return {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    listOrganizationSearched,
    listOrganizationDefault,
    activeOrganizationFilter,
    getTotalSelected,
    onSearchDropdown,
    onFilterDropdown,
    onSelectItem,
    selectedOrganizationFilter,
    selectedIds,
    totalSelectedText: getTotalSelectedText(),
    setSelectedOrganizationSuccess: success,
    successListOrganizationDropdown: isSuccess
  }
}
