import * as React from 'react'
import { Card, Skeleton } from '@mui/material'
import { NppTypography, NppTooltip, NppLink, NppSpinnerProgress } from '@ui/Components'

interface NppCardProps {
  className?: string
  children?: React.ReactNode
  title?: string
  tooltipTitle?: string
  description?: string
  button?: string
  actionButton?: string
  typeButton?: 'navigate' | 'href'
  alignButton?: 'left' | 'right' | 'center'
  rightHeader?: React.ReactNode
  loading?: boolean
}

const NppCard: React.FC<NppCardProps> = ({
  className = '',
  children = null,
  title = '',
  tooltipTitle = '',
  description = '',
  button = '',
  actionButton = '#',
  typeButton = 'navigate',
  alignButton = 'right',
  rightHeader = null,
  loading
}) => {
  const renderTitle = () =>
    loading ? (
      <Skeleton variant="text" width={200} height={30} />
    ) : (
      <NppTypography className="Card_header-title">
        {title}
        {tooltipTitle?.length > 0 ? (
          <NppTooltip title={tooltipTitle}>
            <i className="solid-alert-info" />
          </NppTooltip>
        ) : null}
      </NppTypography>
    )

  const renderDescription = () =>
    loading ? (
      <Skeleton variant="text" width={250} height={20} />
    ) : (
      <NppTypography className="Card_header-description">{description}</NppTypography>
    )

  const renderRightHeader = () => (
    <>
      {loading && <Skeleton variant="text" width={100} height={30} />}
      <div className={`Card_header-right-loading${loading ? ' hidden' : ''}`}>{rightHeader}</div>
    </>
  )

  const renderButton = () => {
    const linkProps = typeButton === 'href' ? { href: actionButton } : { navigate: actionButton }
    return loading ? (
      <Skeleton variant="text" width={80} height={40} />
    ) : (
      <NppLink className={`Card_footer-button`} {...linkProps}>
        {button}
      </NppLink>
    )
  }
  return (
    <Card className="Card_wrapper">
      {title ? (
        <div className="Card_header-wrapper">
          <div className="Card_header-left">
            {renderTitle()}
            {renderDescription()}
          </div>
          <div className="Card_header-right">{renderRightHeader()}</div>
        </div>
      ) : null}
      <div className={`Card_content-wrapper${loading ? '-loading' : ''} ${className}`}>
        {loading ? <NppSpinnerProgress /> : children}
      </div>
      {button ? <div className={`Card_footer-wrapper align-${alignButton}`}>{renderButton()}</div> : null}
    </Card>
  )
}

export default NppCard
