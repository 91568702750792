import * as React from 'react'
import i18n from 'i18n'
import usePartnerDropdown from '@pages/sharedHooks/usePartnerDropdown'
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock'
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection'
import AlertHtmlPageSection from '@ui/Sections/Shared/AlertHtmlPageSection'
import BannerHtmlPageSection from '@ui/Sections/Shared/BannerHtmlPageSection'
import PartnerHeaderSection from '@ui/Sections/Shared/PartnerHeaderSection'
import { BillingCard, WidgetSection } from 'bundles/features/reportingDashboard'
import { useAppSelector } from 'bundles/hooks'
import useCreatePartnerAlert from './pageHooks/useCreatePartnerAlert'

export default function ReportingDashboardPage() {
  const { t } = i18n

  const {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    getTotalSelected,
    onFilterDropdown,
    onSelectItem,
    listPartnerSearched,
    listPartnerDefault,
    totalSelectedText,
  } = usePartnerDropdown()

  const isLoading = useAppSelector((state) => state.reportingDashboard.isLoading)
  useCreatePartnerAlert()

  return (
    <div className="ReportingDashboard__wrapper">
      <HeaderPageBlock title={t('reporting_dashboard.index.title')} />
      <BannerHtmlPageSection />
      <AlertHtmlPageSection />
      <AlertPageSection />
      <PartnerHeaderSection
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelected}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        getTotalSelected={getTotalSelected}
        onFilterDropdown={onFilterDropdown}
        onSelectItem={onSelectItem}
        listPartnerSearched={listPartnerSearched}
        listPartnerDefault={listPartnerDefault}
        totalSelectedText={totalSelectedText}
        showAddPartner
      />
      <BillingCard />
      <WidgetSection />
    </div>
  )
}
