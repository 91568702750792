import { createApi } from "@reduxjs/toolkit/query/react";
import WebApiRoutes from "bundles/config/Routes/WebApiRoutes";
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const listOrganizationApi = createApi({
  reducerPath: "listOrganizationApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getListOrganizationDropdown: builder.query<any, any>({
      query: (params) => {
        const queryParam = new URLSearchParams(params)
        return {
          url: `${WebApiRoutes.listOrganizationDropdownPath()}?${queryParam}`,
        }
      },
      transformResponse: (response: any) => response.data
    }),
    setSelectedOrganization: builder.mutation<any, any>({
      query: (data) => ({
        url: WebApiRoutes.setSelectedOrganizationPath(),
        method: 'PUT',
        headers: { 'X-CSRF-Token': data.csrfToken },
        body: {
          organizations: data.organizations,
          path: data.path
        }
      }),
    }),
  }),
});

export const { useGetListOrganizationDropdownQuery, useLazyGetListOrganizationDropdownQuery, useSetSelectedOrganizationMutation } = listOrganizationApi;