import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getSeatUsageApi } from '@services/ApiServices'
import BaseSetting from '@config/BaseSetting'

interface GetSeatUsageApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

interface PayloadInterface {
  partner_ids?: string[]
}

const getSeatUsageAsync = createAsyncThunk(
  'ReportingDashboard/getSeatUsage',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getSeatUsageApi({ ...payload, csrfToken: BaseSetting.csrfToken })
    return data as GetSeatUsageApiData // Adjust the type accordingly
  }
)

const resetSeatUsage = createAction('ReportingDashboard/resetSeatUsage')

const getSeatUsageExtraReducers = (builder: any) => {
  builder
    .addCase(getSeatUsageAsync.pending, (state) => {
      state.getSeatUsage = true
    })
    .addCase(getSeatUsageAsync.rejected, (state) => {
      state.getSeatUsage = false
    })
    .addCase(getSeatUsageAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.seatUsage = action.payload.data.data
      } else {
        state.error = action.payload.error
      }
      state.getSeatUsage = false
    })
    .addCase(resetSeatUsage, (state) => {
      state.seatUsage = {}
      state.getSeatUsage = false
      state.error = null
    })
}

export { getSeatUsageAsync, getSeatUsageExtraReducers, resetSeatUsage }
