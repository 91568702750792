import { createApi } from "@reduxjs/toolkit/query/react";
import WebApiRoutes from "bundles/config/Routes/WebApiRoutes";
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const bsrRequestApi = createApi({
  reducerPath: "listBsrRequestApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getBsrRequest: builder.query<any, any>({
      query: (params) => {
        const queryParam = new URLSearchParams(params)
        return {
          url: `${WebApiRoutes.getBsrRequestPath()}?${queryParam}`,
        }
      },
      transformResponse: (response: any) => ({
        bsrExists: response?.bsr_exists,
        data: response?.data,
      })
    }),
    createBsrRequest: builder.mutation<any, any>({
      query: (payload) => ({
        url: WebApiRoutes.createBsrRequestPath(),
        method: 'POST',
        headers: { 'X-CSRF-Token': payload.csrfToken },
        body: {
          ...payload.body
        }
      }),
    })
  }),
});

export const { useGetBsrRequestQuery, useLazyGetBsrRequestQuery, useCreateBsrRequestMutation } = bsrRequestApi;