import * as React from 'react'
import BaseSetting from '@config/BaseSetting'
import { NppDropdown, NppButton } from '@ui/Components'

interface NppPaginationProps {
  openRowsPerPage?: boolean
  pageNumber: number
  hasNextLink: boolean | null
  hasPrevLink: boolean | null
  onClickNextLink: () => void
  onClickPrevLink: () => void
  onChangePageNumber: (selected: number) => void
  paginationLabel: string
}

const options = BaseSetting.paginationPerPageNumbers.map((o) => {
  return [o, <span>{o}</span>]
})

const NppPagination: React.FC<NppPaginationProps> = ({
  openRowsPerPage,
  pageNumber,
  hasNextLink,
  hasPrevLink,
  onClickNextLink,
  onClickPrevLink,
  onChangePageNumber,
  paginationLabel
}) => {
  return (
    <div className={`NppPagination__wrapper ${!openRowsPerPage ? 'NppPagination__without-row-per-page' : ''}`}>
      {openRowsPerPage && (
        <div className="NppPagination__select">
          {paginationLabel || 'Row per page:'}
          <NppDropdown
            menuItems={options}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClickItem={(selected: number) => {
              onChangePageNumber(selected)
            }}
            dropdownClassName="NppPagination__dropdown-menu">
            {pageNumber}
          </NppDropdown>
        </div>
      )}
      <div className="NppPagination__control">
        <NppButton disabled={!hasPrevLink} onClick={onClickPrevLink}>
          <i className="solid-chevron-left" />
        </NppButton>
        <NppButton disabled={!hasNextLink} onClick={onClickNextLink}>
          <i className="solid-chevron-right" />
        </NppButton>
      </div>
    </div>
  )
}

NppPagination.defaultProps = {
  openRowsPerPage: true,
  hasNextLink: null,
  hasPrevLink: null,
  pageNumber: 10,
  onClickNextLink: () => {},
  onClickPrevLink: () => {},
  onChangePageNumber: () => {},
  paginationLabel: ''
}

export default NppPagination
