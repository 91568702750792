export function numberToPercentage(number: number, ofNumber: number, decimalPlaces: number = 2): number {
  // Check if inputs are valid numbers and ofNumber is not 0 to avoid division by zero
  if (typeof number !== 'number' || typeof ofNumber !== 'number' || ofNumber === 0) {
    return 0; // Return 0% or another default value as appropriate
  }

  return parseFloat(((number / ofNumber) * 100).toFixed(decimalPlaces))
}

export function roundNumber(type: 'ceil' | 'floor' | 'round' = 'round', number) {
  if (!number) return 0
  if (type == 'ceil') return Math.ceil(number)
  if (type == 'floor') return Math.floor(number)
  if (type == 'round') return Math.round(number)
}
