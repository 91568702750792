import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getBillingApi } from '@services/ApiServices'

interface GetBillingApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

const getBillingAsync = createAsyncThunk('ReportingDashboard/getBilling', async () => {
  const data = await getBillingApi()
  return data as GetBillingApiData // Adjust the type accordingly
})

const resetBilling = createAction('ReportingDashboard/resetBilling')

const getBillingExtraReducers = (builder: any) => {
  builder
    .addCase(getBillingAsync.pending, (state) => {
      state.getBilling = true
    })
    .addCase(getBillingAsync.rejected, (state) => {
      state.getBilling = false
    })
    .addCase(getBillingAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.billing = action.payload.data
      } else {
        state.error = action.payload.error
      }
      state.getBilling = false
    })
    .addCase(resetBilling, (state) => {
      state.billing = {}
      state.getBilling = false
      state.error = null
    })
}

export { getBillingAsync, getBillingExtraReducers, resetBilling }
