import * as React from 'react'
import { NppCardListItem, NppPagination, NppTypography } from '@ui/Components'

interface PaginationCardBlockProps {
  hasNextLink?: boolean | null
  hasPrevLink?: boolean | null
  onPrevPage: () => void | void
  onNextPage: () => void | void
  onChangePageNumber: (selected: number) => void | void
  pageNumber?: number | null
  showPagination: boolean
  paginationLabel?: string
  className?: string
  showFooterSelectedItems?: boolean
  selectedItems?: number
  openRowsPerPage?: boolean;
}

const PaginationCardBlock: React.FC<PaginationCardBlockProps> = ({
  showPagination,
  hasNextLink,
  hasPrevLink,
  onPrevPage,
  onNextPage,
  onChangePageNumber,
  pageNumber,
  paginationLabel,
  className,
  showFooterSelectedItems,
  selectedItems,
  openRowsPerPage
}) => {
  if (!showPagination) return null

  const renderFooterSelectedItems = () => {
    if (!showFooterSelectedItems) return null

    return <NppTypography variant="caption">{`${selectedItems} Items Selected`}</NppTypography>
  }

  return (
    <NppCardListItem
      className={`PaginationCardBlock__wrapper ${className || ''}`}
      justifyContent="center"
      alignItems="center"
      leftContent={renderFooterSelectedItems()}>
      <NppPagination
        hasNextLink={hasNextLink}
        hasPrevLink={hasPrevLink}
        onClickPrevLink={onPrevPage}
        onClickNextLink={onNextPage}
        onChangePageNumber={onChangePageNumber}
        pageNumber={pageNumber}
        paginationLabel={paginationLabel}
        openRowsPerPage={openRowsPerPage}
      />
    </NppCardListItem>
  )
}

PaginationCardBlock.defaultProps = {
  hasNextLink: null,
  hasPrevLink: null,
  onPrevPage: () => {},
  onNextPage: () => {},
  onChangePageNumber: () => {},
  pageNumber: null,
  showPagination: false,
  paginationLabel: '',
  className: '',
  showFooterSelectedItems: false,
  selectedItems: 0,
  openRowsPerPage: true
}

export default PaginationCardBlock
