import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAccountsApi } from "@services/ApiServices";

interface getAccountsApiData {
  data: object;
  error: string | null;
}

interface PayloadInterface {
  partner_ids?: string[]
  start_date?: string
  end_date?: string
}

const getAccountsAsync = createAsyncThunk(
  "ReportingDashboard/getAccounts",
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getAccountsApi({ ...payload });
    return data as getAccountsApiData;
  }
);

const resetAccounts = createAction("ReportingDashboard/resetAccounts");

const getAccountsExtraReducers = (builder: any) => {
  builder
    .addCase(getAccountsAsync.pending, (state) => {
      state.getAccounts = true;
    })
    .addCase(getAccountsAsync.rejected, (state) => {
      state.getAccounts = false;
    })
    .addCase(getAccountsAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.accounts = action.payload.data.data;
      } else {
        state.error = action.payload.error;
      }
      state.getAccounts = false;
    })
    .addCase(resetAccounts, (state) => {
      state.accounts = {};
      state.getAccounts = false;
      state.error = null;
    });
};

export { getAccountsAsync, getAccountsExtraReducers, resetAccounts };
