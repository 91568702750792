import * as React from 'react'
import i18n from 'i18n'
import { seatUsageReportPath } from '@config/Routes/WebClientRoutes'
import { useAppSelector } from "bundles/hooks"
import WidgetCardBlock from 'bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock'
import useSeatUsageChart from '../hooks/useSeatUsageChart'
import useMasterPartner from 'bundles/features/listPartnerDropdown/hooks/useMasterPartner';

const SeatUsageChart = () => {
  const { t } = i18n
  const isMasterPartner = useMasterPartner()
  const { data, chartData, isFetching } = useSeatUsageChart()

  return (
    <WidgetCardBlock
      title={t('reporting_dashboard.index.seat_usage_card.header')}
      description={t('reporting_dashboard.index.seat_usage_card.sub_header', {
        date: data?.updated_at ?? ''
      })}
      tooltipTitle={t('reporting_dashboard.index.seat_usage_card.info_header')}
      button={t('reporting_dashboard.index.seat_usage_card.report_detail_button')}
      actionButton={seatUsageReportPath}
      isLoading={isFetching}
      cardInfo={t(
        `reporting_dashboard.index.seat_usage_card.description${!isMasterPartner ? '_without_partner' : ''}_html`,
        {
          organizations: data?.organizations_count ?? 0,
          partners: data?.resellers_count ?? 0
        }
      )}
      chartData={chartData}
      chartType="gauge"
      chartTitle={t('reporting_dashboard.index.seat_usage_card.chart_label')}
      showValueChart
      chartInfo={t('reporting_dashboard.index.seat_usage_card.chart_description_html', {
        paid_seat: data?.used_seat ?? 0,
        total_paid: data?.paid_seat ?? 0
      })}
    />
  )
}

export default SeatUsageChart
