import * as React from 'react';
import { ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Badge from '@mui/material/Badge';
import { NppTypography, NppTooltip } from '@ui/Components';
import { useNavigate } from 'react-router-dom';

interface TooltipWrapperProps {
  condition: boolean;
  children: ReactNode;
  title: string;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ condition, children, title }) => {
  return condition ? (
    <NppTooltip title={title}>
      <span className="NppButton__tooltip">{children}</span>
    </NppTooltip>
  ) : (
    <>{children}</>
  );
};

interface NppButtonProps {
  sx?: React.CSSProperties;
  sxText?: React.CSSProperties;
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  navigate?: string;
  asDropdown?: boolean;
  startIcon?: ReactNode;
  showBadge?: boolean;
  hidden?: boolean;
  className?: string;
  title?: string;
  withTitle?: boolean;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any; // To capture other props
}

const NppButton: React.FC<NppButtonProps> = ({
  sx,
  sxText,
  children,
  onClick,
  navigate,
  asDropdown,
  startIcon,
  showBadge,
  hidden,
  className,
  title,
  withTitle,
  disabled,
  loading,
  ...rest
}) => {
  const routeNavigate = useNavigate();

  const onClickButton = (e: React.MouseEvent) => {
    if (typeof onClick === 'function') onClick(e);
    if (typeof navigate === 'string') routeNavigate(navigate);
  };

  const styles: React.CSSProperties = {
    textTransform: 'inherit',
  };

  const renderStartIcon = () => {
    if (showBadge) {
      return (
        <Badge
          className="NppButton__badge"
          color="primary"
          variant="dot"
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          {startIcon}
        </Badge>
      );
    }
    return startIcon;
  };

  if (hidden) {
    return null;
  }

  return (
    <TooltipWrapper condition={withTitle || false} title={title || ''}>
      <LoadingButton
        sx={{
          ...styles,
          ...sx,
        }}
        {...rest}
        onClick={(e: React.MouseEvent) => {
          if (!disabled) onClickButton(e);
        }}
        startIcon={renderStartIcon()}
        className={`NppButton__default ${className || ''} ${
          loading ? 'NppButton__loading' : ''
        }`}
        disabled={disabled}
        loading={loading}
      >
        <NppTypography sx={sxText}>{children}</NppTypography>
        {asDropdown && <KeyboardArrowDownIcon className="NppButton__dropdown-icon" />}
      </LoadingButton>
    </TooltipWrapper>
  );
};

export default NppButton;


NppButton.defaultProps = {
  sx: {},
  sxText: {},
  children: '',
  onClick: undefined,
  navigate: undefined,
  asDropdown: false,
  startIcon: null,
  showBadge: false,
  hidden: false,
  className: '',
  title: '',
  withTitle: false,
  disabled: false,
  loading: false,
};
