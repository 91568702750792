import BaseSetting from '@config/BaseSetting'
import Routes from './Routes'

/**
 * Contains web api urls
 * */

class WebApiRoutes extends Routes {
  //
  // routes
  // endpoint defined here
  // format: [url_name]Path
  //
  protectionCoveragePath(params = {}, format = '') {
    return this.createUrl('/dashboard/protection_coverage/widget/data', params, format)
  }

  seatUsagePath(params = {}, format = '') {
    return this.createUrl('/dashboard/seat_usage/widget/data', params, format)
  }

  organizationPath(params = {}, format = '') {
    return this.createUrl('/reporting_dashboard/chart/organizations.json', params, format)
  }

  accountPath(params = {}, format = '') {
    return this.createUrl('/reporting_dashboard/chart/accounts.json', params, format)
  }

  backgroundJobStatusPath(params = {}, format = '') {
    return this.createUrl('/dashboard/job_status/widget/data.json', params, format)
  }

  protectionCoverageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/protection_coverage/data', params, format)
  }

  seatUsageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/seat_usage/data', params, format)
  }

  listPartnerDropdownPath(params = {}, format = '') {
    return this.createUrl('/partners/partners.json', params, format)
  }
  
  listOrganizationDropdownPath(params = {}, format = '') {
    return this.createUrl('/organizations_list.json', params, format)
  }

  partnerTypePath(params = {}, format = '') {
    return this.createUrl('/dashboard/partner_type', params, format)
  }

  billingPath(params = {}, format = '') {
    return this.createUrl('/dashboard/outstanding_invoice/data', params, format)
  }
  
  setListPartnerDropdownPath(params = {}, format = '') {
    return this.createUrl('/set_selected_partners', params, format)
  }

  downloadCSVReportProtectionCoveragePath(params = {}, format = '') {
    return this.createUrl('/dashboard/protection_coverage/export/csv', params, format)
  }

  downloadCSVReportSeatUsagePath(params = {}, format = '') {
    return this.createUrl('/dashboard/seat_usage/export/csv', params, format)
  }

  activeChartsPath(params = {}, format = '') {
    return this.createUrl('/dashboard/active_chart', params, format)
  }

  getBsrRequestPath(params = {}, format = ''){
    return this.createUrl('/bsr/index', params, format)
  }

  createBsrRequestPath(params = {}, format = ''){
    return this.createUrl('/bsr/create', params, format)
  }

  getAccountPath(params = {}, format = ''){
    return this.createUrl('/accounts/email.json', params, format)
  }

  setSelectedOrganizationPath(params = {}, format = '') {
    return this.createUrl('/set_selected_organizations', params, format)
  }

  getBsrRequestItemPath(params = {}, format = ''){
    return this.createUrl('/bsr_item/index', params, format)
  }

  generateBsrRequestItemDownloadLinkPath(params = {}, format = ''){
    return this.createUrl('/bsr_item/generate_download_link', params, format)
  }
}

const ApiRoutes = new WebApiRoutes()

export const protectionCoveragePath = ApiRoutes.protectionCoveragePath()
export const seatUsagePath = ApiRoutes.seatUsagePath()
export const protectionCoverageReportPath = ApiRoutes.protectionCoverageReportPath()
export const seatUsageReportPath = ApiRoutes.seatUsageReportPath()
export const listPartnerDropdownPath = ApiRoutes.listPartnerDropdownPath()
export const partnerTypePath = ApiRoutes.partnerTypePath()
export const backgroundJobStatusPath = ApiRoutes.backgroundJobStatusPath()

export default ApiRoutes
