import { createApi } from "@reduxjs/toolkit/query/react";
import WebApiRoutes from "bundles/config/Routes/WebApiRoutes";
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const bsrRequestItemApi = createApi({
  reducerPath: "listBsrRequestItemApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getBsrRequestItem: builder.query<any, any>({
      query: (params) => {
        const queryParam = new URLSearchParams(params)
        return {
          url: `${WebApiRoutes.getBsrRequestItemPath()}?${queryParam}`,
        }
      },
      transformResponse: (response: any) => response.data
    }),
    generateBsrRequestItemDownloadLink: builder.query<any, any>({
      query: (params) => {
        const queryParam = new URLSearchParams(params)
        return {
          url: `${WebApiRoutes.generateBsrRequestItemDownloadLinkPath()}?${queryParam}`,
        }
      },
      transformResponse: (response: any) => response.data
    }),
  }),
});

export const { useGetBsrRequestItemQuery, useLazyGetBsrRequestItemQuery, useLazyGenerateBsrRequestItemDownloadLinkQuery } = bsrRequestItemApi;