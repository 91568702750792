import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getProtectionCoverageApi } from '@services/ApiServices'
import BaseSetting from '@config/BaseSetting'

interface GetProtectionCoverageApiData {
  data: object // Adjust the type accordingly
  error: string | null
}

interface PayloadInterface {
  partner_ids?: string[]
}

const getProtectionCoverageAsync = createAsyncThunk(
  'ReportingDashboard/getProtectionCoverage',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getProtectionCoverageApi({ ...payload, csrfToken: BaseSetting.csrfToken })
    return data as GetProtectionCoverageApiData // Adjust the type accordingly
  }
)

const resetProtectionCoverage = createAction('ReportingDashboard/resetProtectionCoverage')

const getProtectionCoverageExtraReducers = (builder: any) => {
  builder
    .addCase(getProtectionCoverageAsync.pending, (state) => {
      state.getProtectionCoverage = true
    })
    .addCase(getProtectionCoverageAsync.rejected, (state) => {
      state.getProtectionCoverage = false
    })
    .addCase(getProtectionCoverageAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.protectionCoverage = action.payload.data.data
      } else {
        state.error = action.payload.error
      }
      state.getProtectionCoverage = false
    })
    .addCase(resetProtectionCoverage, (state) => {
      state.protectionCoverage = {}
      state.getProtectionCoverage = false
      state.error = null
    })
}

export { getProtectionCoverageAsync, getProtectionCoverageExtraReducers, resetProtectionCoverage }
