import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import Litepicker from 'litepicker'
import 'litepicker-module-ranges'
import { NppButton } from '@ui/Components'
interface NppDatePickerProps {
  onDateSelected?: (startDate: Date | string, endDate: Date | string) => void
}

const subtractDays = (date, num) => {
  const d = new Date(date)
  d.setDate(d.getDate() - num)

  return d
}

const thisQuarter = (date) => {
  let current_quarter = Math.ceil((date.getMonth() + 1) / 3)
  const d1 = new Date(date.getFullYear(), current_quarter * 3 - 3, 1)
  const d2 = new Date(date.getFullYear(), current_quarter * 3, 0)

  return [d1, d2]
}

const thisYear = (date) => {
  const d1 = new Date(date)
  d1.setDate(1)
  d1.setMonth(0)
  const d2 = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return [d1, d2]
}

const formatDate = (date:any) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

const startDate = new Date()
const endDate = new Date()

const NppDatePicker: React.FC<NppDatePickerProps> = ({ onDateSelected }) => {
  const [picker, setPicker] = useState(null)
  const [activeText, setActiveText] = useState('This Month')
  const className = 'NppDatePicker__wrapper'
  const buttonIdName = 'litepicker-button-' + Math.random().toString(36).substr(2, 5);

  const addCustomClassToLitepicker = useCallback(() => {
    const litepickerEl = document.querySelector('.litepicker')
    if (litepickerEl) {
      litepickerEl.classList.add(className)
    }
  }, [])

  const customRangeOption = () => {

      // Assuming you're manipulating the DOM directly for something not supported by Litepicker
      const listContainer = document.querySelector('.container__predefined-ranges');
      if (listContainer) {
        const customRangeElement = document.createElement('div');
        customRangeElement.classList.add('lite-picker-disable-click');
        customRangeElement.innerHTML = 'Custom Range';
        customRangeElement.addEventListener('click', (e) => {
          e.preventDefault();
          // Handle custom range selection here
        });
        listContainer.appendChild(customRangeElement);
  
        // Cleanup to remove the element when the component unmounts or updates
        return () => {
          customRangeElement.removeEventListener('click', customRangeElement.click);
          listContainer.removeChild(customRangeElement);
        };
      }; // Re-run this effect if the picker instance changes
  };

  const handleButtonClick = useCallback(() => {
    if (!picker) {
      const newPicker = new Litepicker({
        element: document.getElementById(buttonIdName),
        parentElement: document.getElementById(buttonIdName),
        inlineMode: false,
        format: 'D MMM, YYYY',
        buttonText: { apply: 'Set Filter' },
        dropdowns: {
          minYear: 2021,
          maxYear: null,
          months: false,
          years: false
        },
        autoApply: false,
        numberOfMonths: 2,
        numberOfColumns: 2,
        singleMode: false,
        moduleRanges: {
          ranges: {
            'Last 7 Days': [subtractDays(startDate, 7), endDate],
            'Last 30 Days': [subtractDays(startDate, 30), endDate],
            'This Quarter': thisQuarter(startDate),
            'This Year': thisYear(startDate)
          }
        },
        onSelect: (start, end) => {
          onDateSelected(formatDate(start), formatDate(end))
          setActiveText(`${start.toLocaleDateString()} - ${end.toLocaleDateString()}`)
          newPicker.hide()
        },
        onShow: () => {
          addCustomClassToLitepicker()
          customRangeOption();
        },
      })
      setPicker(newPicker)
      newPicker.show()
    } else {
      picker.show()
      addCustomClassToLitepicker()
    }
  }, [onDateSelected, picker])

  return (
    <NppButton id={buttonIdName} className="NppDatePicker__button" onClick={handleButtonClick} variant="outlined">
      {activeText} <i className="solid-arrow-down" />
    </NppButton>
  )
}

export default NppDatePicker
