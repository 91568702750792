import * as React from 'react'
import i18n from 'i18n'
import { NppTypography, NppDropdown, NppTextField, NppButton } from '@ui/Components'
import Checkbox from '@mui/material/Checkbox'
import BaseSetting from '@config/BaseSetting'

interface PartnerDropdownBlockProps {
  className?: string
  selected: string[]
  setSelected: (selected: string[]) => void
  searchValue: string
  setSearchValue: (string) => void
  getTotalSelected: () => number
  onFilterDropdown: () => void
  onSelectItem: (string) => void
  listPartnerSearched: { id; organization_name }[]
  listPartnerDefault: { id; organization_name }[]
  dropdownText: string
}

const PartnerDropdownBlock: React.FC<PartnerDropdownBlockProps> = ({
  className = '',
  selected,
  setSelected,
  searchValue,
  setSearchValue,
  getTotalSelected,
  onFilterDropdown,
  onSelectItem,
  listPartnerSearched,
  listPartnerDefault,
  dropdownText
}: PartnerDropdownBlockProps) => {
  const { t } = i18n

  const getListPartnerOptions = () => {
    const listPartner =
      searchValue?.length >= BaseSetting.allowTypeRequestLength ? listPartnerSearched : listPartnerDefault
    const listPartnerOptions = listPartner?.map((item) => {
      return [
        item,
        <div className="FilterDropdownBlock__wrapper">
          <Checkbox checked={selected.includes('all') !== selected.includes(item.id)} />
          <NppTypography className="FilterDropdownBlock__item-list" variant="subtitle1">
            {item?.organization_name}
          </NppTypography>
        </div>
      ]
    })
    return listPartnerOptions
  }

  return (
    <NppDropdown
      variant="checkbox"
      menuItems={getListPartnerOptions()}
      onClickItem={(item: { organization_name?: string | number; id?: string | number }) => {
        onSelectItem(item?.id)
      }}
      headerMenu={
        <div className="FilterDropdownBlock__header">
          <NppTextField
            className="FilterDropdownBlock__search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.currentTarget.value)
            }}
            fullWidth
            endIcon={<i className="line-search" />}
          />
          <div className="FilterDropdownBlock__checkbox-header">
            <Checkbox
              checked={selected?.includes('all')}
              onClick={() => {
                setSelected(selected?.includes('all') ? [] : ['all'])
              }}
            />
            <NppTypography className="FilterDropdownBlock__item-list" variant="subtitle1">
              {t('reporting_dashboard.partner_dropdown.select_all')}
            </NppTypography>
          </div>
        </div>
      }
      footerMenu={({ handleMenuClose }) => (
        <div className="FilterDropdownBlock__footer">
          <div className="FilterDropdownBlock__line" />
          <NppButton
            className="FilterDropdownBlock_Button"
            variant="contained"
            onClick={() => {
              onFilterDropdown()
              handleMenuClose()
            }}>
            {t('reporting_dashboard.partner_dropdown.filter_button')}
          </NppButton>
        </div>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      className="NppDropdown__button"
      leftIcon={<i className="line-partner" />}>
      <NppTypography variant="subtitle1">{dropdownText}</NppTypography>
    </NppDropdown>
  )
}

PartnerDropdownBlock.defaultProps = {
  className: ''
}

export default PartnerDropdownBlock
