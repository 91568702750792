import * as React from 'react';
import * as DOMPurify from 'dompurify';

interface SanitizedHtmlProps {
  htmlContent: string;
}

const SanitizedHtml: React.FC<SanitizedHtmlProps> = ({ htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
};

export default SanitizedHtml;
