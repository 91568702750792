import { ReportPeriod } from "../SharedConstants";
import * as dayjs from "dayjs";
import * as UpdateLocale from 'dayjs/plugin/updateLocale'
import { startCase, camelCase } from "lodash";

dayjs.extend(UpdateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})

export function convertReportPeriodToDays(period: ReportPeriod) {
  let start = null, end = null
  if (period === ReportPeriod.Last31Days) {
    end = dayjs().endOf('day').format()
    start = dayjs().subtract(30, 'day').startOf('day').format()
  }
  if (period === ReportPeriod.LastWeek) {
    start = dayjs().subtract(1, 'week').startOf('week').format()
    end = dayjs().subtract(1, 'week').endOf('week').format()
  }
  if (period === ReportPeriod.LastMonth) {
    start = dayjs().subtract(1, 'month').startOf('month').format()
    end = dayjs().subtract(1, 'month').endOf('month').format()
  }
  if (period === ReportPeriod.ThisWeek) {
    start = dayjs().startOf('week').format()
    end = dayjs().endOf('week').format()
  }
  if (period === ReportPeriod.ThisMonth) {
    start = dayjs().startOf('month').format()
    end = dayjs().endOf('month').format()
  }
  return {
    start,
    end
  }
}

export function convertSnakeToNormal(str) {
  return str.replace(/_/g, ' ');
}

export function convertReportPeriodToFirstCase(str) {
  return startCase(camelCase(str))
}

export function isEndTimeExpired(endTime) {
  const endTimePlus31Days = dayjs(endTime).add(31, 'day')
  const isLinkExpired = dayjs().isAfter(endTimePlus31Days)
  return isLinkExpired
}