import { useEffect, useState } from 'react'
import { useGetJobStatusQuery } from '../services/reportingDashboardApi';
import { useAppDispatch, useAppSelector } from 'bundles/hooks';
import { setLoadingStatus } from '..';

const useJobStatusChart = () => {
  const dispatch = useAppDispatch()
  const { partnerIds } = useAppSelector((state) => state.listPartnerDropdown)
  const [ organizationIds, setOrganizationIds ] = useState([])
  const [totalPartner, setTotalPartner] = useState(0)
  const [totalOrganization, setTotalOrganization] = useState(0)
  const { data, isFetching, isSuccess } = useGetJobStatusQuery({ 
    partner_ids: partnerIds, 
    organization_ids: organizationIds
  });
  const [ chartData, setChartData ] = useState({ 
    values: [
      ['Failed or Errors', 0],
      ['In-progress', 0],
      ['Completed', 0]
    ],
    colors: ['#DD252F', '#0075E1', '#36B252'],
    colorThresholds: [40, 80, 100]
  })

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(chartData => ({
        ...chartData,
        values: [
          ['Failed or Errors', data?.failed],
          ['In-progress', data?.in_progress],
          ['Completed', data?.successful]
        ]
      }));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    dispatch(setLoadingStatus({ getJobStatus: isFetching }))
  }, [isFetching])

  const filterByOrganization = (organizationIds: string[]) => {
    setOrganizationIds(organizationIds)
  }

  useEffect(() => {
    setTotalPartner(partnerIds.length)
    setTotalOrganization(organizationIds.length)
  }, [partnerIds, organizationIds])

  return {
    data,
    chartData,
    isFetching,
    filterByOrganization,
    totalPartner,
    totalOrganization
  }
}

export default useJobStatusChart