import * as React from 'react'
import i18n from 'i18n'
import { NppTypography, NppCard } from '@ui/Components'

interface InfoCardBlockProps {
  icon: React.ReactNode
  title: string | number
  subtitle?: string
  description?: string
}

export default function InfoCardBlock({ icon, title, subtitle = '', description }: InfoCardBlockProps) {
  const { t } = i18n

  return (
    <NppCard className="InfoCardBlock__card-info">
      <div className="InfoCardBlock__card-info-left">
        {icon}
      </div>
      <div className="InfoCardBlock__card-info-right">
        <div>
          <NppTypography variant="h3">{title}</NppTypography>
          {subtitle ? <NppTypography variant="subtitle2">&nbsp;{subtitle}</NppTypography> : null}
        </div>
        <NppTypography variant="caption">{description}</NppTypography>
      </div>
    </NppCard>
  )
}
