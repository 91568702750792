import i18n from 'i18n'
const { t } = i18n

const useAlertMessage = () => {
  const konstMessage = {
    SUCCESS_GENERATE_CSV: 'SUCCESS_GENERATE_CSV',
    ERROR_GENERATE_CSV: 'ERROR_GENERATE_CSV'
  }

  const success = 'success'
  const error = 'error'

  const getSuccessMessage = (konst, payload, fallbackMessage = 'Error') => {
    const defaultPayload = { type: success }

    switch (konst) {
      case konstMessage.SUCCESS_GENERATE_CSV:
        return {
          ...defaultPayload,
          heading: t('reporting_dashboard.banner_download_csv.title'),
          message: t('reporting_dashboard.banner_download_csv.description')
        }
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.success_heading')}`
        }
    }
  }

  const getErrorMessage = (konst, payload, fallbackMessage = 'Error') => {
    const defaultPayload = { type: error }

    switch (konst) {
      case konstMessage.ERROR_GENERATE_CSV:
        return {
          ...defaultPayload,
          message: '',
          heading: ''
        }
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.error_heading')}`
        }
    }
  }

  return { getSuccessMessage, getErrorMessage, konstMessage }
}

export default useAlertMessage
