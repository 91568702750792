import ReportingDashboardService from './ReportingDashboardService'
import ApplicationService from './ApplicationService'

// eslint-disable-next-line import/prefer-default-export

export const {
  getProtectionCoverageApi,
  getSeatUsageApi,
  getOrganizationsApi,
  getAccountsApi,
  getProtectionCoverageReportApi,
  getSeatUsageReportApi,
  getListPartnerDropdownApi,
  getBillingApi,
  setListPartnerDropdownApi,
  downloadCSVReportProtectionCoverageApi,
  downloadCSVReportSeatUsageApi
} = ReportingDashboardService

export const { getPartnerTypeApi } = ApplicationService
