//
// @params:
// keys: [String], list: [String], [Array[String]]
//
// eg:
// ['objectName'], ['satu', 'dua', 'tiga']
// ['number', 'objectName'], ['satu', 'dua', 'tiga']
// ['listing', 'objectName'], [ ['1', 'array'], ['2', 'dua'], ['3', 'tiga'] ]
// ['number', 'listing', 'objectName'], [ ['1', 'array'], ['2', 'dua'], ['3', 'tiga'] ]
//

const CONST_NUMBER = 'number'

/* eslint-disable no-param-reassign */
export const flattenAttributes = (attributes) => {
  const flattenHelper = (currentObject, newAttributes, previousKeyName) => {
    const objectKeys = Object.keys(currentObject)

    objectKeys.forEach((key) => {
      const value = currentObject[key]

      if ((value && ![Object, Array].includes(value.constructor)) || !value) {
        let injectValue = value

        if (injectValue === false) injectValue = 'false'
        if (injectValue === true) injectValue = 'true'
        if ([undefined, null].includes(injectValue)) injectValue = 'null'
        if (injectValue && injectValue.constructor !== String) injectValue = injectValue.toString()

        if (previousKeyName === null || previousKeyName === '') {
          newAttributes[key] = injectValue
        } else if (key === null || key === '') {
          newAttributes[previousKeyName] = injectValue
        } else {
          newAttributes[`${previousKeyName} - ${key}`] = injectValue
        }
      } else if (previousKeyName === null || previousKeyName === '') {
        flattenHelper(value, newAttributes, key)
      } else {
        flattenHelper(value, newAttributes, `${previousKeyName} - ${key}`)
      }
    })
  }

  const newAttributes = {}
  flattenHelper(attributes, newAttributes, '')

  return newAttributes
}

/* eslint-disable no-console */
export const asyncFlattenAttributes = (attributes) => {
  return new Promise((resolve) => {
    try {
      resolve(flattenAttributes(attributes))
    } catch (err) {
      console.error(err.message)
      resolve({})
    }
  })
}

// This function aims to push data into an array based on parameters,
// if the data already exists then the data in the array will be deleted
// and if there is no data it will be inputted.
export const pushSpliceEntry = (array, change) => {
  if (!Array.isArray(array)) return []

  const newArray = [...array]
  const arrayPosition = array.indexOf(change)
  if (!newArray.includes(change)) {
    newArray.push(change)
  } else if (arrayPosition !== -1) {
    newArray.splice(arrayPosition, 1)
  }
  return newArray
}

export const sliceArrayWithArray = (array, change) => {
  if (!Array.isArray(array)) return []
  if (!Array.isArray(change)) return []

  // eslint-disable-next-line func-names
  const newArray = array.filter(function (e) {
    return this.indexOf(e) < 0
  }, change)
  return newArray
}

export const loadMoreArray = (array, limit, skip) => {
  if (!Array.isArray(array)) return []
  const newArray = [...array]
  return newArray.splice(0, limit + limit * skip)
}

type SearchableArrayElement = string | boolean | { [key: string]: any }

export const filterArrayByValue = <T extends SearchableArrayElement>(
  array: T[],
  searchValue: string | boolean,
  propertyName?: keyof any,
  caseInsensitive: boolean = true // Only applies if searchValue is a string
): T[] => {
  // Convert searchValue to lowercase string if it's a string and caseInsensitive is true
  const normalizedSearchValue =
    typeof searchValue === 'string' && caseInsensitive ? searchValue.toLowerCase() : searchValue

  if (!Array.isArray(array)) return []

  if (typeof array[0] === 'string' || typeof array[0] === 'boolean') {
    return array.filter((element) => {
      if (typeof element === 'string' && typeof normalizedSearchValue === 'string') {
        // Case-insensitive comparison for strings
        return element.toLowerCase().includes(normalizedSearchValue)
      } else if (typeof element === 'boolean' && typeof normalizedSearchValue === 'boolean') {
        // Direct comparison for booleans
        return element === normalizedSearchValue
      }
      return false // If element type does not match searchValue type
    }) as T[]
  } else if (propertyName) {
    return array.filter((element) => {
      const propValue = element[propertyName]
      if (typeof propValue === 'string' && typeof normalizedSearchValue === 'string') {
        // Normalize property value for case-insensitive comparison if needed
        const normalizedPropValue = caseInsensitive ? propValue.toLowerCase() : propValue
        return normalizedPropValue.includes(normalizedSearchValue)
      } else if (typeof propValue === 'boolean' && typeof normalizedSearchValue === 'boolean') {
        // Direct comparison for booleans
        return propValue === normalizedSearchValue
      }
      return false // If property type does not match searchValue type
    }) as T[]
  } else {
    return array
  }
}

export const filterArrayByKey = <T extends SearchableArrayElement>(
  array: T[],
  key: keyof any
): Partial<T>[] => {
  return array.map((item) => item[key] as Partial<T>)
}
