import { createContext } from 'react'

// Define the context interface
interface PropsContext {
  pageAlertHtml: string | null
  pageBannerHtml: string | null
}

// Create the context with a default value
const PropsContext = createContext<PropsContext | undefined>(undefined)

export default PropsContext
