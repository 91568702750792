import ServiceClientError from './ServiceClientError';
import axiosBaseQuery from './axiosBaseQuery';

class BaseQueryFactory {
  serviceType: string;
  options: object;

  constructor(options, serviceType = 'axios') {
    this.serviceType = serviceType;
    this.options = options;
  }

  create() {
    if (this.serviceType === 'axios') {
      return axiosBaseQuery;
    }

    throw new ServiceClientError('serviceType is not defined.');
  }
}

export default BaseQueryFactory;
