import * as React from 'react'
import SanitizedHtml from '@ui/Blocks/Shared/SanitizedHtml'
import { usePropsContext } from '@hooks/index'

export default function AlertHtmlPageSection({}) {
  const { pageAlertHtml } = usePropsContext()

  return (
    <div className="recommended-notif-warning-banner">
      {pageAlertHtml ? <SanitizedHtml htmlContent={pageAlertHtml} /> : null}
    </div>
  )
}
