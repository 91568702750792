import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import PropsContext from '@contexts/PropsContext'
import routes from '../navigation'
import store from '../store'
import '../config/themes/default'
import '../../fonts/dropsuite-solid-icon/css/dropsuite-solid.css'

function ClientRouterApp(_props) {
  // eslint-disable-next-line react/display-name

  return (
    <Provider store={store}>
      <PropsContext.Provider value={_props}>
        <BrowserRouter>{routes}</BrowserRouter>
      </PropsContext.Provider>
    </Provider>
  )
}

export default ClientRouterApp
