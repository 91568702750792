import { createApi } from "@reduxjs/toolkit/query/react";
import WebApiRoutes from "bundles/config/Routes/WebApiRoutes";
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const accountApi = createApi({
  reducerPath: "listAccountApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getAccount: builder.query<any, any>({
      query: (params) => {
        const queryParam = new URLSearchParams(params)
        return {
          url: `${WebApiRoutes.getAccountPath()}?${queryParam}`,
        }
      },
      transformResponse: (response: any) => response.data
    }),
  }),
});

export const { useGetAccountQuery, useLazyGetAccountQuery } = accountApi;