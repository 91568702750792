import * as React from 'react'
import { Box, Typography, Stack, Link, Avatar } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SummarizeAt } from 'bundles/ui/SharedConstants'
import { PdfIcon, CsvIcon } from 'bundles/ui/SvgIcons'
import i18n from 'i18n'

const { t } = i18n

export function SampleImage(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box component={'img'} src={props.imageSrc} sx={{ width: '100%', mb: 1.5 }} />
      <Typography
        sx={{
          fontSize: 78,
          fontWeight: 600,
          color: '#0000004D',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)'
        }}>
        {t('reporting_dashboard.new_backup_summary_report.sample')}
      </Typography>
    </Box>
  )
}

export function ErrorMessage(props) {
  return (
    <Stack direction="row">
      {props.errorText && <InfoOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />}
      <Typography variant="nppOverline">{props.errorText}</Typography>
    </Stack>
  )
}

export function SummarizedFormHelperText(props) {
  if (props.selectedSummarizeAt === SummarizeAt.OrganizationLevel) {
    return (
      <>
        <Typography variant="nppOverline" sx={{ display: 'inline', fontWeight: 600 }}>
          {`${t('reporting_dashboard.new_backup_summary_report.organization_level')}: `}
        </Typography>
        <Typography variant="nppOverline" sx={{ display: 'inline' }}>
          {`${t('reporting_dashboard.new_backup_summary_report.organization_level_note')} `}
        </Typography>
        <Link
          onClick={props.onClickOrganizationSample}
          component="span"
          underline="none"
          variant="nppOverline"
          sx={{ '& .MuiLink-root': { color: 'npp.blue.50' }, display: 'inline', cursor: 'pointer' }}>
          {t('reporting_dashboard.new_backup_summary_report.see_sample')}
        </Link>
      </>
    )
  }
  if (props.selectedSummarizeAt === SummarizeAt.AccountLevel) {
    return (
      <>
        <Typography variant="nppOverline" sx={{ display: 'inline', fontWeight: 600 }}>
          {`${t('reporting_dashboard.new_backup_summary_report.account_level')}: `}
        </Typography>
        <Typography variant="nppOverline" sx={{ display: 'inline' }}>
          {`${t('reporting_dashboard.new_backup_summary_report.organization_level_note')} `}
        </Typography>
        <Link
          onClick={props.onClickAccountSample}
          component="button"
          underline="none"
          variant="nppOverline"
          sx={{ '& .MuiLink-root': { color: 'npp.blue.50' }, display: 'inline', cursor: 'pointer' }}>
          {t('reporting_dashboard.new_backup_summary_report.see_sample')}
        </Link>
      </>
    )
  }
}

export function PdfLabel() {
  return (
    <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1.5}>
      <Avatar sx={{ bgcolor: 'npp.primary.2' }}>
        <PdfIcon />
      </Avatar>
      <Typography>{t('reporting_dashboard.new_backup_summary_report.options.pdf')}</Typography>
    </Stack>
  )
}

export function CsvLabel() {
  return (
    <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1.5}>
      <Avatar sx={{ bgcolor: 'npp.primary.2' }}>
        <CsvIcon />
      </Avatar>
      <Typography>{t('reporting_dashboard.new_backup_summary_report.options.csv')}</Typography>
    </Stack>
  )
}
