import WebApiRoutes from '@config/Routes/WebApiRoutes'
import ServiceClientFactory from '@libs/ServiceClient'

const webClientApi = () => {
  return new ServiceClientFactory({}).create()
}

const ApplicationService = {
  getPartnerTypeApi: (payload = {}) => {
    return webClientApi().getRequest({
      url: WebApiRoutes.partnerTypePath()
    })
  },
}

export default ApplicationService
