import axios from 'axios'
import ServiceClient from './ServiceClient'

export default class AxiosServiceClient extends ServiceClient {
  // constants
  ERROR_UNAUTHORIZED = 'Unauthorized'

  // client of service
  client = axios

  // exclude from refresh auth token
  refreshTokenExcludedUrl = []

  // refresh token url
  refreshTokenUrl = null

  constructor({ baseUrl, accessToken }) {
    super({ baseUrl, accessToken })

    // baseUrl not needed right now
    // this.client.defaults.baseURL = baseUrl;
    this.client.defaults.headers.common.Accept = 'application/json'
    this.client.defaults.headers.common['Content-Type'] = 'application/json'
    this.client.defaults.withCredentials = true

    this.updateAccessTokenHeader(accessToken)
  }

  updateAccessTokenHeader(accessToken = null, reset = false) {
    if (accessToken && (!this.client.defaults.headers.common.Authorization || reset)) {
      this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    }
  }

  getClient() {
    return this.client
  }

  postRequest({ url, parameters = {}, config = {} }) {
    return this.client.post(url, parameters, config)
  }

  getRequest({ url, parameters = {} }) {
    return this.client.get(url, parameters)
  }

  putRequest({ url, parameters = {} }) {
    return this.client.put(url, parameters)
  }
}
