import * as React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { NppTypography } from '@ui/Components';

// If circularProgress needs to accept props in the future, define an interface for them
const CircularProgressWithStyles = () => {
  return (
    <div style={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#F2F4F6',
        }}
        size={30}
        thickness={4}
        value={100}
        data-testid="progress"
      />

      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#1C63D5',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={30}
        thickness={4}
      />
    </div>
  );
};

interface NppSpinnerProgressProps {
  fullWrapper?: boolean;
  label?: string;
  sx?: React.CSSProperties;
  className?: string;
}

const NppSpinnerProgress: React.FC<NppSpinnerProgressProps> = ({
  sx = {},
  fullWrapper = true,
  label,
  className = '',
}) => {
  return (
    <div
      style={sx}
      className={`NppSpinnerProgress__wrapper ${className} ${
        fullWrapper ? 'NppSpinnerProgress__full-wrapper' : ''
      }`}>
      {CircularProgressWithStyles()}
      {label && <NppTypography>{label}</NppTypography>}
    </div>
  );
};

export default NppSpinnerProgress;
