import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { setListPartnerDropdownApi } from '@services/ApiServices'
import BaseSetting from '@config/BaseSetting'

interface setListPartnerDropdownApiData {
  data: [] // Adjust the type accordingly
  error: string | null
}

interface PayloadInterface {
  all_partner?: number
  authenticity_token?: string
  partners?: string[]
  path?: string
}

const setListPartnerDropdownAsync = createAsyncThunk(
  'ReportingDashboard/setListPartnerDropdown',
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await setListPartnerDropdownApi({ ...payload, authenticity_token: BaseSetting.csrfToken })
    return data as setListPartnerDropdownApiData // Adjust the type accordingly
  }
)

const resetSetListPartnerDropdown = createAction('ReportingDashboard/resetSetListPartnerDropdown')

const setListPartnerDropdownExtraReducers = (builder: any) => {
  builder
    .addCase(setListPartnerDropdownAsync.pending, (state) => {
      state.submitPartner.submitting = true
    })
    .addCase(setListPartnerDropdownAsync.rejected, (state) => {
      state.submitPartner.submitting = false
    })
    .addCase(setListPartnerDropdownAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.submitPartner.success = true
      } else {
        state.submitPartner.success = false
      }
      state.submitPartner.submitting = false
    })
    .addCase(resetSetListPartnerDropdown, (state) => {
      state.submitPartner.error = {}
      state.submitPartner.submitting = false
      state.submitPartner.success = false
    })
}

export { setListPartnerDropdownAsync, setListPartnerDropdownExtraReducers, resetSetListPartnerDropdown }
