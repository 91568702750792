
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  listOrganizationDropdown: [],
  getOrganizationDropdown: true,
  successOrganizationDropdown: false,
  error: null,
  submitOrganization: {
    error: null,
    submitting: false,
    success: false
  },
  organizationIds: []
}

const organizationDropdownSlice = createSlice({
  name: 'listOrganizationDropdown',
  initialState,
  reducers: {
    resetOrganizationDropdown(state) {
      state.listOrganizationDropdown = initialState.listOrganizationDropdown
      state.getOrganizationDropdown = initialState.getOrganizationDropdown
      state.successOrganizationDropdown = initialState.successOrganizationDropdown
      state.error = initialState.error
    },
    resetSetOrganizationDropdown(state) {
      state.submitOrganization = initialState.submitOrganization
    },
    setOrganizationIds(state, action) {
      state.organizationIds = action.payload
    }
  }
})

export const {
  resetOrganizationDropdown,
  resetSetOrganizationDropdown,
  setOrganizationIds
} = organizationDropdownSlice.actions

export default organizationDropdownSlice.reducer
