// Font Color
export const fontPrimaryColor = '#42526E';
export const fontMuteColor = '#77808F';
export const fontMuteColor2 = '#9EA5B0';
export const fontActiveColor = '#1C63D5';

// BG Color
export const bgSuccessColor = '#2CA01C';
export const bgIconSuccess = '#0F7469';
export const bgIconError = '#D51C25';
export const bgIconInfo = '#0075E1';
export const bgBlueColor = fontActiveColor;

// Font
export const fontFamily = 'Inter';
export const fontSize = '1.4rem';
