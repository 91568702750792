import * as React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { childrenToString, pascalToTitleCase } from "@utilities/index";

interface NppTypographyProps extends TypographyProps {
  withTitle?: boolean;
  wrap?: boolean;
}

const NppTypography: React.FC<NppTypographyProps> = ({
  sx,
  className,
  children,
  withTitle,
  wrap,
  ...rest
}) => {
  
  const color = Object.keys(rest).includes("color") && rest?.color[0] !== '#'
  ? `MuiTypography-color${pascalToTitleCase(rest.color as string)}`
  : ""
  
  const styles = {
    fontFamily: "Inter",
    ...(Object.keys(rest).includes("variant") ? {} : { fontSize: "1.4rem" }),
    ...(Object.keys(rest).includes("color") && rest?.color[0] === '#' ? {color: rest?.color } : {}),
  };

  return (
    <Typography
      title={withTitle ? childrenToString(children) : undefined}
      noWrap={!wrap}
      className={`${className} ${color}`}
      sx={{
        ...styles,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

NppTypography.defaultProps = {
  sx: {},
  children: "",
  withTitle: false,
  className: "",
  wrap: false,
};

export default NppTypography;
