import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    nppH1: React.CSSProperties;
    nppH2: React.CSSProperties;
    nppH3: React.CSSProperties;
    nppBodyTitle: React.CSSProperties;
    nppOverline: React.CSSProperties;
    nppBodyText: React.CSSProperties;
    nppParagraphSm: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    nppH1?: React.CSSProperties;
    nppH2?: React.CSSProperties;
    nppH3?: React.CSSProperties;
    nppBodyTitle?: React.CSSProperties;
    nppOverline?: React.CSSProperties;
    nppBodyText?: React.CSSProperties;
    nppParagraphSm?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    nppH1: true;
    nppH2: true;
    nppH3: true;
    nppBodyTitle: true;
    nppOverline: true;
    nppBodyText: true;
    nppParagraphSm: true;
  }
}

export const Theme = createTheme({
  palette: {
    npp: {
      primary: {
        1: '#F3F4F5',
        2: '#E9EBED',
        3: '#C7CCD1'
      },
      grey: {
        100: '#E9EBED',
        800: '#1B2942'
      },
      blue: {
        20: '#E3F0FC',
        50: '#0075E1'
      },
      green: {
        20: '#E3FCE9',
        30: '#A8DCB4',
      },
      red: {
        20: '#FCE3E6',
      },
      black: {
        80: '#1D2431'
      }
    }
  },
  typography: {
    nppH1: {
      fontSize: 28,
      fontWeight: 600,
      display: 'block',
      lineHeight: 1.5
    },
    nppH2: {
      fontSize: 22,
      fontWeight: 600,
      display: 'block',
      lineHeight: 1.5
    },
    nppH3: {
      fontSize: 18,
      fontWeight: 600,
      display: 'block',
      lineHeight: 1.5
    },
    nppBodyTitle: {
      color: '#4E5154',
      fontSize: 14,
      display: 'block',
      lineHeight: 1.5
    },
    nppOverline: {
      fontSize: 12,
      display: 'block',
      lineHeight: 1.5
    },
    nppBodyText: {
      fontSize: 14,
      display: 'block',
      lineHeight: 1.5
    },
    nppParagraphSm: {
      fontSize: 14,
      display: 'block',
      lineHeight: 2
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          color: '#4E5154',
          variants: [
            {
              props: { severity: 'error' },
              style: {
                backgroundColor: '#FCE3E6',
                border: '1px solid #F4A9B3'
              }
            },
            {
              props: { severity: 'info' },
              style: {
                backgroundColor: '#E3F0FC',
                border: '1px solid #8CC4F8'
              }
            },
            {
              props: { severity: 'success' },
              style: {
                backgroundColor: '#E3FCE9',
                border: '1px solid #A8DCB4'
              }
            }
          ]
        }
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          color: '#1D2431'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: {variant: 'contained', color: 'primary'},
              style: {
                '&:hover': {
                  backgroundColor: '#0082fb',
                  color: '#fff'
                },
                '&:focus': {
                  outline: 'unset'
                }
              }
            }
          ]
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          border: '1px solid #C7CCD1',
          borderRadius: 4
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'unset'
        }
      }
    }
  }
})