import * as React from "react";
// import Chart from 'chart.js/auto'; // Import Chart.js library
import { Chart as ChartJS } from "chart.js";
import { NppTypography } from "@ui/Components";

interface NppChartProps {
  data: { values: number[]; labels?: number[] | string[]; colors?: string[] };
  type?: "line" | "doughnut";
  title?: string | number;
  showValue?: boolean;
}

const NppChart = ({ data, type, title, showValue }: NppChartProps) => {
  const { useEffect, useRef } = React;
  const chartRef = useRef(null);

  const ChartTypeLine = {
    type: "line",
    data: {
      labels: data.labels,
      datasets: [
        {
          label: title,
          data: data.values,
          backgroundColor: data.colors?.[0] || "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: "label",
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        xAxes: [
          {
            display: false,
            scaleLabel: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
          },
        ],
      },
    },
  };

  const ChartTypeDoughnut = {
    type: "doughnut",
    data: {
      labels: data.labels,
      datasets: [
        {
          label: title,
          data: data.values,
          backgroundColor: data.colors || ["#B9262E", "#D7DBE3"],
          hoverOffset: 4,
        },
      ],
    },
    options: {
      cutoutPercentage: 65,
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  let ChartType = {
    line: ChartTypeLine,
    doughnut: ChartTypeDoughnut,
  };

  useEffect(() => {
    // Create chart when component mounts
    const ctx = chartRef.current.getContext("2d");
    new ChartJS(ctx, ChartType[type]);
  }, [data]);

  return (
    <div className="Chart__wrapper">
      <canvas ref={chartRef} width="20rem" />
      {showValue ? (
        <NppTypography className="Chart__value" variant="h1">
          {data?.values[0]}%
        </NppTypography>
      ) : null}
      {title ? (
        <div className="Chart__title">
          <span
            className="Chart__title-dot"
            style={{
              backgroundColor: data?.colors?.[0],
            }}
          ></span>
          <NppTypography className="Chart__title-text">{title}</NppTypography>
        </div>
      ) : null}
    </div>
  );
};

NppChart.defaultProps = {
  type: "line",
  label: "",
  showValue: false,
};

export default NppChart;
