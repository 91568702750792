/**
 * Routes
 * Parent Class for building url
 * */

class Routes {
  // declare private variables
  // that can not be accessed
  // from outside
  #config: { baseUrl: string } = { baseUrl: "" };

  // This base URL "http://host/" is required in order to create a URL object to simplify url parameters manipulation.
  // The result that will be returned is only the path and parameters without the host.
  constructor(baseUrl: string = "http://host/") {
    this.#config.baseUrl = baseUrl;
  }

  getBaseUrl(): string {
    return this.#config.baseUrl;
  }

  createUrl(
    path: string,
    params: Record<string, string | undefined> = {},
    format = ""
  ): string {
    let pathUrl = path;
    if (format !== "") pathUrl = `${pathUrl}.${format}`;

    const url = new URL(pathUrl, this.getBaseUrl());
    const paramKeys = Object.keys(params);
    const buildParams = Object.keys(params);

    // inject to url
    if (paramKeys.length > 0) {
      paramKeys.forEach((key) => {
        const keyInPath = `:${key}`;
        const value = params[key];

        if (url.pathname.includes(keyInPath) && value) {
          url.pathname = url.pathname.replace(keyInPath, value);

          // remove the key from array
          // to avoid multiple parameters
          buildParams.splice(buildParams.indexOf(key), 1);
        }
      });
    }
    // add as parameters
    if (buildParams.length > 0) {
      buildParams.forEach((key) => {
        if (params[key]) url.searchParams.append(key, params[key]!);
      });
    }

    return url.pathname + url.search;
  }
}

export default Routes;
