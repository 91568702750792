import * as React from "react"
import WidgetCardBlock from "bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock"
import { protectionCoverageReportPath } from '@config/Routes/WebClientRoutes'
import i18n from "i18n"
import { useAppSelector } from "bundles/hooks"
import useProtectionCoverageChart from "../hooks/useProtectionCoverageChart"
import useMasterPartner from 'bundles/features/listPartnerDropdown/hooks/useMasterPartner';

const ProtectionCoverageChart = () => {
  const { t } = i18n
  const isMasterPartner = useMasterPartner()
  const { data, chartData, isFetching } = useProtectionCoverageChart()

  return (
    <WidgetCardBlock
      title={t('reporting_dashboard.index.protection_coverage_card.header')}
      description={t('reporting_dashboard.index.protection_coverage_card.sub_header', {
        date: data?.updated_at ?? ''
      })}
      tooltipTitle={t('reporting_dashboard.index.protection_coverage_card.info_header')}
      button={t('reporting_dashboard.index.protection_coverage_card.report_detail_button')}
      actionButton={protectionCoverageReportPath}
      isLoading={isFetching}
      cardInfo={t(
        `reporting_dashboard.index.protection_coverage_card.description${
          !isMasterPartner ? '_without_partner' : ''
        }_html`,
        {
          organizations: data?.organizations_count ?? 0,
          partners: data?.resellers_count ?? 0
        }
      )}
      chartData={chartData}
      chartType="gauge"
      chartTitle={t('reporting_dashboard.index.protection_coverage_card.chart_label')}
      showValueChart
      chartInfo={t('reporting_dashboard.index.protection_coverage_card.chart_description_html', {
        accounts_backed_up: data?.protected_account_count ?? 0,
        total_accounts: data?.total_account_count ?? 0
      })}
      sideInfoTitle={data?.unprotected_account_count ?? 0}
      sideInfoDescription={t('reporting_dashboard.index.protection_coverage_card.description_2')}
    />
  )
}

export default ProtectionCoverageChart