import * as React from 'react'
import i18n from 'i18n'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import {
  getSeatUsageReportAsync,
  resetSeatUsageReport,
  downloadCSVReportAsync,
  resetDownloadCSVReport
} from '@features/seatUsageReport/seatUsageReportSlice'
import { getSeatUsageAsync, resetSeatUsage } from '@features/reportingDashboard/reportingDashboardSlice'
import { getPartnerTypeAsync, resetPartnerType } from '@features/application/applicationSlice'
import { NppTypography, NppLink } from '@ui/Components'
import { numberToPercentage, roundNumber } from '@utilities/index'
import { usePageNumber, useAlertMessage, usePageAlert } from '@hooks/index'
import { TableColumn, TableRowData } from '@ui/Components/NppSimpleDataTable'

interface TableHeader extends TableColumn {}

interface TableRow extends TableRowData {
  [key: string]: string | number | boolean | React.ReactNode
}

const defaultOrder = 'asc'
const defaultOrderColumn = 'seatUsage'

const idColumn = 'id'
const organizationNameColumn = 'organizationName'
const partnerNameColumn = 'partnerName'
const unusedSeatColumn = 'unusedSeat'
const usedSeatColumn = 'usedSeat'
const paidSeatColumn = 'paidSeat'
const seatUsageColumn = 'seatUsage'

const partnerTypePartner = 'Partner'

const listColumn = [
  idColumn,
  organizationNameColumn,
  partnerNameColumn,
  unusedSeatColumn,
  usedSeatColumn,
  paidSeatColumn,
  seatUsageColumn
]

const colorList = ['#DD252F', '#E7A300', '#36B252']

const getColorPercentage = (value: number): string => {
  if (value < 40) return colorList[0]
  if (value < 80) return colorList[1]
  if (value <= 100) return colorList[2]
  return colorList[0]
}

const createHeader = (
  id: number,
  label: string,
  columnName: string,
  orderable: boolean,
  size: string,
  align: string,
  tooltipText: string = ''
): TableHeader => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: false,
    align: 'left',
    tooltipText,
    wrap: true
  }
}

const createData = (
  id: number | string,
  organizationName: string,
  partner: string,
  unusedSeat: number,
  usedSeat: number,
  paidSeat: number,
  seatUsage: number,
  organizationId?: number | string,
  regionId?: number | string
): TableRow => {
  return {
    id: `${organizationId}-${regionId}`,
    organizationName: (
      <NppLink href={`/organizations/${organizationId}-${regionId}/subscriptions`} underline="hover" withTitle>
        {organizationName}
      </NppLink>
    ),
    partnerName: (
      <NppTypography color="mute" noWrap title={partner}>
        {partner}
      </NppTypography>
    ),
    unusedSeat: (
      <NppTypography color="mute" noWrap>
        {unusedSeat}
      </NppTypography>
    ),
    usedSeat: (
      <NppTypography color="mute" noWrap>
        {usedSeat}
      </NppTypography>
    ),
    paidSeat: (
      <NppTypography color="mute" noWrap>
        {paidSeat}
      </NppTypography>
    ),
    seatUsage: (
      <NppTypography color={getColorPercentage(seatUsage) ?? colorList[0]} noWrap>
        {seatUsage}%
      </NppTypography>
    )
  }
}

export default function useFetchReportingDashboardWidget({ partnerIds = [] }) {
  const { t } = i18n
  const prefixTranslation = 'reporting_dashboard.seat_usage_report.table.column'
  const dispatch = useAppDispatch()
  const { getSuccessMessage, konstMessage } = useAlertMessage()
  const { updateSuccessAlert } = usePageAlert()
  const { seatUsageReport, getSeatUsageReport } = useAppSelector((state) => state.seatUsageReport)
  const { seatUsage, getSeatUsage } = useAppSelector((state) => state.reportingDashboard)
  const { partnerType, getPartnerType } = useAppSelector((state) => state.application)

  const [order, setOrder] = useState<'asc' | 'desc'>(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderColumn)
  const [page, setPage] = useState(1)
  const [pageNumber, setPageNumber] = usePageNumber()
  const [showModalCsv, setShowModalCsv] = useState<boolean>(false)
  const [pageAction, setPageAction] = useState(null)

  const tableHeaders = [
    createHeader(1, t(`${prefixTranslation}.id`), idColumn, false, '8.571rem', 'left'),
    createHeader(2, t(`${prefixTranslation}.organization_name`), organizationNameColumn, false, '16.5rem', 'left'),
    createHeader(2, t(`${prefixTranslation}.partner_name`), partnerNameColumn, false, '16.5rem', 'left'),
    createHeader(3, t(`${prefixTranslation}.unused_seat`), unusedSeatColumn, false, 'auto', 'left', t(`${prefixTranslation}.unused_seat_tooltip`)),
    createHeader(4, t(`${prefixTranslation}.used_seat`), usedSeatColumn, false, 'auto', 'left', t(`${prefixTranslation}.used_seat_tooltip`)),
    createHeader(5, t(`${prefixTranslation}.paid_seat`), paidSeatColumn, false, 'auto', 'left'),
    createHeader(6, t(`${prefixTranslation}.seat_usage`), seatUsageColumn, false, 'auto', 'left', t(`${prefixTranslation}.seat_usage_tooltip`))
  ]

  const tableRows = seatUsageReport?.data?.map((item) =>
    createData(
      item?.id,
      item?.organization_name,
      item?.reseller_name,
      item?.unused_seat,
      item?.used_seat,
      item?.paid_seat,
      item?.seat_usage,
      item?.organization_id,
      item?.region_id
    )
  )

  useEffect(() => {
    const payload = {
      page,
      sort_column: listColumn.indexOf(orderBy),
      sort_direction: order,
      partner_ids: partnerIds,
      page_size: pageNumber,
      page_action: pageAction
    }

    if (seatUsageReport?.pagination_state && page > 1) {
      payload['pagination_state'] = JSON.stringify(seatUsageReport?.pagination_state)
    }

    dispatch(getSeatUsageReportAsync(payload))

    return () => {
      dispatch(resetSeatUsageReport())
    }
  }, [dispatch, page, pageNumber, order, partnerIds])

  useEffect(() => {
    dispatch(getSeatUsageAsync({ partner_ids: partnerIds }))

    return () => {
      dispatch(resetSeatUsage())
    }
  }, [dispatch, partnerIds])

  useEffect(() => {
    dispatch(getPartnerTypeAsync())

    return () => {
      dispatch(resetPartnerType())
    }
  }, [dispatch])

  const hasPrevPage = (): boolean => {
    return page != 1
  }

  const hasNextPage = (): boolean => {
    return (
      seatUsageReport?.is_last_page !== true
    )
  }

  const onPrevPage = () => {
    setPageAction('prev')
    if (hasPrevPage) setPage(page - 1)
  }

  const onNextPage = () => {
    setPageAction('next')
    if (hasNextPage) setPage(page + 1)
  }

  const onChangePageNumber = (number) => {
    setPage(1)
    setPageAction(null)
    setPageNumber(number)
  }

  const handleDownloadCsv = () => {
    const criteria = {
      filters: { partner: partnerIds?.length === 0 ? 'All' : partnerIds },
      status: { generating: false },
      page: 'seat-usage-report',
      url: '/dashboard/seat-usage-report'
    }

    const payload = {
      criteria: JSON.stringify(criteria),
      file_type: 'csv'
    }
    dispatch(downloadCSVReportAsync(payload)).then(() => {
      dispatch(resetDownloadCSVReport())
    })
  }

  const onClickConfirmationModal = () => {
    handleDownloadCsv()
    setShowModalCsv(false)
    const alertPayload = {
      ...getSuccessMessage(konstMessage.SUCCESS_GENERATE_CSV, {})
    }

    updateSuccessAlert(alertPayload)
  }

  return {
    isLoading: getSeatUsageReport && getSeatUsage && getPartnerType,
    seatUsageData: seatUsage,
    seatUsageReportDate: seatUsageReport,
    tableHeaders,
    tableRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    pageNumber,
    setPageNumber,
    hasPrevPage: hasPrevPage(),
    hasNextPage: hasNextPage(),
    onPrevPage,
    onNextPage,
    onChangePageNumber: onChangePageNumber,
    isMasterPartner: partnerType?.partner_type === partnerTypePartner,
    showModal: showModalCsv,
    onOpenModal: () => setShowModalCsv(true),
    onCloseModal: () => setShowModalCsv(false),
    onClickConfirmationModal
  }
}
