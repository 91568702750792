import * as React from 'react'
import i18n from 'i18n'
import useFetchReportingDashboardWidget from './pageHooks/useFetchReportingDashboardWidget'
import { reportingDashboardPath } from '@config/Routes/WebClientRoutes'
import usePartnerDropdown from '@pages/sharedHooks/usePartnerDropdown'
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection'
import PartnerHeaderSection from '@ui/Sections/Shared/PartnerHeaderSection'
import InfoCardSection from '@ui/Sections/ProtectionCoverageReport/InfoCardSection'
import DataTableSection from '@ui/Sections/Shared/DataTableSection'
import ModalDownloadCSVCardBlock from '@ui/Blocks/Shared/CardBlock/ModalDownloadCSVCardBlock'
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock'

export default function ProtectionCoverageReport() {
  const { t } = i18n

  const {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    getTotalSelected,
    onFilterDropdown,
    onSelectItem,
    listPartnerSearched,
    listPartnerDefault,
    selectedIds,
    totalSelectedText
  } = usePartnerDropdown()

  const {
    isLoading,
    protectionCoverageData,
    tableHeaders,
    tableRows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    hasPrevPage,
    hasNextPage,
    pageNumber,
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    isMasterPartner,
    showModal,
    onOpenModal,
    onCloseModal,
    onClickConfirmationModal
  } = useFetchReportingDashboardWidget({ partnerIds: selectedIds ?? [] })

  return (
    <div className="ProtectionCoverageReport__wrapper">
      <ModalDownloadCSVCardBlock
        showModal={showModal}
        isMasterPartner={isMasterPartner}
        onCloseModal={onCloseModal}
        onClickConfirmationModal={onClickConfirmationModal}
        description={t('reporting_dashboard.modal_download_csv.protection_coverage_description')}
        selectedPartnerText={totalSelectedText}
        lastUpdateTime={protectionCoverageData?.updated_at ?? ''}
      />

      <HeaderPageBlock
        title={t('reporting_dashboard.protection_coverage_report.title')}
        showBackButton
        backButtonLabel={t('reporting_dashboard.protection_coverage_report.back_to_dashboard')}
        backButtonLink={reportingDashboardPath}
      />

      <AlertPageSection />

      <PartnerHeaderSection
        isMasterPartner={isMasterPartner}
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelected}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        getTotalSelected={getTotalSelected}
        onFilterDropdown={onFilterDropdown}
        onSelectItem={onSelectItem}
        listPartnerSearched={listPartnerSearched}
        listPartnerDefault={listPartnerDefault}
        totalSelectedText={totalSelectedText}
      />

      <AlertPageSection
        alertType="info"
        alertMessage={t(
          `reporting_dashboard.protection_coverage_report.information${!isMasterPartner ? '_without_partner' : ''}`,
          {
            organization: protectionCoverageData?.organizations_count ?? 0,
            partners: protectionCoverageData?.resellers_count ?? 0,
            date: protectionCoverageData?.updated_at ?? ''
          }
        )}
      />

      <InfoCardSection protectionCoverageData={protectionCoverageData} />

      <DataTableSection
        title={t('reporting_dashboard.protection_coverage_report.table.title')}
        headerLinkText={t('reporting_dashboard.protection_coverage_report.table.export_csv_button')}
        headerLinkOnClick={() => onOpenModal()}
        rows={tableRows}
        header={tableHeaders}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        loading={isLoading}
        onClickRow={(row) => {}}
        hasNextLink={hasNextPage}
        hasPrevLink={hasPrevPage}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onChangePageNumber={onChangePageNumber}
        pageNumber={pageNumber}
        showPagination={true}
        openRowsPerPage={true}
      />
    </div>
  )
}
