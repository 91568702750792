
import { useAppDispatch } from 'bundles/hooks'
import { useGetBillingQuery } from '..'
import { setLoadingStatus } from '..'
import { useEffect } from 'react'

const useBillingCard = () => {
  const dispatch = useAppDispatch()
  const { data: billingData, isFetching: isLoading } = useGetBillingQuery()

  useEffect(() => {
    dispatch(setLoadingStatus({ getBilling: isLoading }))
  }, [isLoading])

  return {
    billingData,
    isLoading
  }
}

export default useBillingCard
