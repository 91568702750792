export enum AlertType {
  success = 'success',
  error = 'danger'
}

interface PayloadInterface {
  heading?: string
  message?: string
  type?: AlertType
}

const useHtmlPageAlert = () => {
  let payload: PayloadInterface
  let titleElement = document.querySelector('#alert-title') as HTMLElement,
    contentElement = document.querySelector('#alert-content') as HTMLElement,
    typeElement = document.querySelector('#alert-type') as HTMLElement

  if (titleElement && contentElement && typeElement) {
    payload = {
      heading: titleElement.innerText,
      message: contentElement.innerText,
      type: typeElement.innerText as AlertType
    }
    titleElement.innerHTML = ''
    contentElement.innerHTML = ''
    typeElement.innerHTML = ''
  }

  return {
    payload
  }
}

export default useHtmlPageAlert
