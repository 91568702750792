import * as React from 'react'
import { ReactNode, CSSProperties, MouseEvent } from 'react'
import { Link as MuiLink } from '@mui/material'
import { fontFamily, fontSize, fontActiveColor } from '@config/themes/default/variables'
import { useNavigate } from 'react-router-dom'
import { childrenToString } from '@utilities/index'

interface NppLinkProps {
  sx?: CSSProperties
  children: ReactNode | ReactNode[]
  navigate?: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  className?: string
  withTitle?: boolean
  underline?: 'none' | 'hover' | 'always'
  [key: string]: any; // To capture other props
}

const styles: CSSProperties = {
  fontFamily,
  fontSize,
  color: fontActiveColor,
  textDecorationColor: `${fontActiveColor} !important`
}

const NppLink: React.FC<NppLinkProps> = ({
  sx,
  children,
  onClick,
  navigate,
  className,
  withTitle,
  underline,
  ...rest
}: NppLinkProps) => {
  const routeNavigate = useNavigate()

  const onClickLink = (e: MouseEvent<HTMLAnchorElement>) => {
    if (typeof onClick === 'function') onClick(e)
    if (typeof navigate === 'string') routeNavigate(navigate)
  }

  return (
    <MuiLink
      noWrap
      title={withTitle ? childrenToString(children) : undefined}
      className={`NppLink__wrapper ${navigate || onClickLink ? 'NppLink__with-navigate' : ''} ${className}`}
      underline={underline}
      sx={{
        ...styles,
        ...sx
      }}
      onClick={onClickLink}
      {...rest}>
      {children}
    </MuiLink>
  )
}

NppLink.defaultProps = {
  sx: {},
  children: '',
  onClick: undefined,
  navigate: undefined,
  className: '',
  withTitle: false,
  underline: 'none'
}

export default NppLink
