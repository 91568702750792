import * as React from "react";
import { useEffect, useState } from "react";
import { AccountChart, JobStatusChart, OrganizationChart, ProtectionCoverageChart, SeatUsageChart, useGetActiveChartsQuery } from "..";

function showActiveChart(chartActiveStatus) {
  if(chartActiveStatus !== undefined){
    return chartActiveStatus
  } else {
    return true
  }
}

const useActiveCharts = () => {
  const { data, isSuccess } = useGetActiveChartsQuery()

  const [chartList, setChartList] = useState([
    { name: 'job_status', active: false, component: <JobStatusChart /> },
    { name: 'protection_coverage', active: false, component: <ProtectionCoverageChart /> },
    { name: 'seat_usage', active: false, component: <SeatUsageChart /> },
    { name: 'organization', active: false, component: <OrganizationChart /> },
    { name: 'account', active: false, component: <AccountChart /> },
  ]);

  useEffect(() => {
    if (isSuccess && data) {
      setChartList((prev) => {
        return prev.map((chart) => {
          return {
            ...chart,
            active: showActiveChart(data[chart.name])
          }
        });
      });
    }
  }, [isSuccess, data])
  
  
  const getActiveCharts = () => {
    return chartList.filter((chart) => chart.active);
  };
  
  const activeCharts = getActiveCharts();

  return {
    activeCharts
  };
};

export default useActiveCharts;