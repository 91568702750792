import * as React from 'react'
import i18n from 'i18n'
import { NppDatePicker } from '@ui/Components'
import WidgetCardBlock from 'bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock'
import { organizationsPath } from 'bundles/config/Routes/WebClientRoutes'
import useOrganizationChart from '../hooks/useOrganizationChart'

const OrganizationChart = () => {
  const { t } = i18n
  const { chartData, isFetching, handleDateSelected } = useOrganizationChart()

  return (
    <WidgetCardBlock
      title={t('reporting_dashboard.index.organization_card.header')}
      description={'20 Feb 2024 at 10:49'}
      button={t('reporting_dashboard.index.organization_card.report_detail_button')}
      actionButton={organizationsPath}
      typeButton="href"
      isLoading={isFetching}
      rightHeader={
        <NppDatePicker 
          onDateSelected={(startDate, endDate) => handleDateSelected(startDate.toString(), endDate.toString())} 
        />
      }
      chartData={chartData}
      chartType="multiple"
      showValueChart
    />
  )
}

export default OrganizationChart
