import { useEffect, useState } from "react";
import { useGetPartnerTypeQuery } from "../services/listPartnerApi";
import { s } from "redom";

const useMasterPartner = () => {
  const [isMasterPartner, setIsMasterPartner] = useState<boolean>(false);

  const { data, isSuccess, error } = useGetPartnerTypeQuery();

  useEffect(() => {
    if (isSuccess) {
      setIsMasterPartner(data.partner_type === "Partner");
    }
  }, [isSuccess, data]);

  return isMasterPartner;
}

export default useMasterPartner;