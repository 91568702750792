import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface NppCheckboxProps {
  className?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label?: React.ReactNode;
}

const NppCheckbox: React.FC<NppCheckboxProps> = ({
  className = '',
  checked = false,
  onChange = () => {},
  label,
  ...rest
}) => (
  <FormGroup
    className={`NppCheckbox__wrapper ${
      label === '' ? 'NppCheckbox__without-label' : ''
    } ${className}`}
  >
    <FormControlLabel
      control={<Checkbox {...rest} checked={checked} onChange={onChange} />}
      label={label}
    />
  </FormGroup>
);

export default NppCheckbox;
