import * as React from 'react'
import i18n from 'i18n'
import { NppTypography, NppLink } from '@ui/Components'

interface HeaderPageBlockProps {
  title: string
  showBackButton?: boolean
  backButtonLabel?: string
  backButtonLink?: string
  children?: React.ReactNode
}

export default function HeaderPageBlock({
  title = '',
  showBackButton = false,
  backButtonLabel = '',
  backButtonLink = '',
  children = null
}: HeaderPageBlockProps) {
  const { t } = i18n

  return (
    <div className='HeaderPageBlock__wrapper'>
      {showBackButton ? (
        <NppLink className="HeaderPageBlock__back-button" navigate={backButtonLink}>
          <i className="solid-chevron-left" /> {backButtonLabel}
        </NppLink>
      ) : null}
      <NppTypography className="HeaderPageBlock__title" variant="h1">
        {title}
      </NppTypography>
      {children}
    </div>
  )
}
