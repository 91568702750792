import { useEffect } from 'react';
import BaseSetting from '@config/BaseSetting';

interface UseSearchDelayParams {
  actionFunc: () => void;
  resetFunc?: () => void;
  onChangeKeyword?: () => void;
}

export default function useSearchDelay(
  { actionFunc, resetFunc, onChangeKeyword }: UseSearchDelayParams,
  keyword: string,
  moreTriggers: any[] = [], // Specify a more specific type if possible
  allowNumberChar: number = BaseSetting.allowTypeRequestLength
) {
  useEffect(() => {
    if (onChangeKeyword) onChangeKeyword();

    const delayID = setTimeout(() => {
      if (keyword.length >= allowNumberChar) {
        actionFunc();
      }

      if (keyword.length === 0 && resetFunc) {
        resetFunc();
      }
    }, BaseSetting.searchTypeDelay);

    return () => clearTimeout(delayID);
  }, [keyword, ...moreTriggers]);

  return [];
}