import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "bundles/hooks"
import { numberToPercentage } from "bundles/utilities"
import { useGetSeatUsageQuery } from "../services/reportingDashboardApi"
import { setLoadingStatus } from ".."

const useSeatUsageChart = () => {
  const dispatch = useAppDispatch()
  const { partnerIds } = useAppSelector((state) => state.listPartnerDropdown)
  const { data, isFetching, isSuccess } = useGetSeatUsageQuery({ partner_ids: partnerIds });
  const [ chartData, setChartData ] = useState({ 
    values: 0,
    colors: ['#DD252F', '#E7A300', '#36B252'],
    colorThresholds: [40, 80, 100]
  })

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(chartData => ({
        ...chartData,
        values: numberToPercentage(data?.used_seat, data?.paid_seat) ?? 0
      }));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    dispatch(setLoadingStatus({ getSeatUsage: isFetching }))
  }, [isFetching])

  return {
    data,
    chartData,
    isFetching
  }
}

export default useSeatUsageChart
