import StorageFactory from '@libs/Storage';

// Initialize storage factory for local storage with a specific key prefix
const storageFactory = new StorageFactory({ keyStoragePrefix: 'QBO_USE_STORAGE' }, 'localStorage');
const storageClient = storageFactory.create();

export const clearStorage = (): void => {
  // Directly call a method to clear storage if supported by your storageClient
  storageClient.clear();
};

const useLocalStorage = <T>(key: string): [T | null, (value: T) => void, () => void] => {
  // Function to save value to storage
  const setValue = (value: T): void => {
    try {
      // Serialize value if it's an object/array, otherwise store as is
      const serializedValue = typeof value === 'object' ? JSON.stringify(value) : value;
      storageClient.save(key, serializedValue);
    } catch (e) {
      console.error('Error saving to storage', e);
    }
  };

  // Function to retrieve and parse value from storage
  const getValue = (): T | null => {
    const storedValue = storageClient.get(key);
    if (!storedValue) return null;
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      // Return storedValue directly if it's not JSON-serializable
      return storedValue as unknown as T;
    }
  };

  // Function to delete value from storage
  const deleteValue = (): void => {
    storageClient.destroy(key);
  };

  const value: T | null = getValue();

  return [value, setValue, deleteValue];
};

export default useLocalStorage;