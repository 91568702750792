import * as React from 'react'
import i18n from 'i18n'
import { NppCard, NppTypography, NppSimpleDataTable, NppLink } from '@ui/Components'
import { TableColumn, TableRowData } from '@ui/Components/NppSimpleDataTable'
import PaginationCardBlock from '@ui/Blocks/Shared/CardBlock/PaginationCardBlock'

interface DataTableSectionProps {
  title?: string
  headerLinkText?: string
  headerLinkOnClick?: () => void
  loading: boolean
  header: TableColumn[]
  rows: TableRowData[]
  onClickRow?: (row: TableRowData) => void
  order?: 'asc' | 'desc'
  setOrder?: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>
  orderBy?: string
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>
  hasNextLink?: boolean | null
  hasPrevLink?: boolean | null
  onPrevPage: () => void | void
  onNextPage: () => void | void
  onChangePageNumber: (selected: number) => void | void
  pageNumber?: number | null
  showPagination: boolean
  paginationLabel?: string
  openRowsPerPage?: boolean;
}

const DataTableSection = ({
  title = '',
  headerLinkText = '',
  headerLinkOnClick = () => {},
  loading,
  header,
  rows,
  onClickRow  = () => {},
  order = 'asc',
  setOrder = () => {},
  orderBy = '',
  setOrderBy = () => {},
  hasNextLink = false,
  hasPrevLink = false,
  onPrevPage = () => {},
  onNextPage = () => {},
  onChangePageNumber = () => {},
  pageNumber = 1,
  showPagination = false,
  openRowsPerPage = false,
}: DataTableSectionProps) => {
  const { t } = i18n

  return (
    <div className="DataTableSection__content-wrapper">
      <NppCard className="DataTableSection__table-wrapper">
        <div className="DataTableSection__table-header">
          {title ? (
            <NppTypography variant="h3" color="secondary">
              {title}
            </NppTypography>
          ) : null}
          {headerLinkText ? (
            <NppLink onClick={() => headerLinkOnClick()}>
              <i className="solid-download" />{' '}
              {headerLinkText}
            </NppLink>
          ) : null}
        </div>
        <NppSimpleDataTable
          className="DataTableSection__table"
          rows={rows}
          header={header}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loading={loading}
          onClickRow={(row) => {}}
          withCheckboxSelection={false}
        />
        <PaginationCardBlock
          className="DataTableSection__pagination-wrapper"
          hasNextLink={hasNextLink}
          hasPrevLink={hasPrevLink}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          showPagination={showPagination}
          openRowsPerPage={openRowsPerPage}
        />
      </NppCard>
    </div>
  )
}

export default DataTableSection
