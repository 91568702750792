import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getListPartnerDropdownApi } from '@services/ApiServices'

interface getListPartnerDropdownApiData {
  data: [] // Adjust the type accordingly
  error: string | null
}

const getListPartnerDropdownAsync = createAsyncThunk('ReportingDashboard/getListPartnerDropdown', async () => {
  const data = await getListPartnerDropdownApi()
  return data as getListPartnerDropdownApiData // Adjust the type accordingly
})

const resetListPartnerDropdown = createAction('ReportingDashboard/resetListPartnerDropdown')

const getListPartnerDropdownExtraReducers = (builder: any) => {
  builder
    .addCase(getListPartnerDropdownAsync.pending, (state) => {
      state.getListPartnerDropdown = true
    })
    .addCase(getListPartnerDropdownAsync.rejected, (state) => {
      state.getListPartnerDropdown = false
    })
    .addCase(getListPartnerDropdownAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.listPartnerDropdown = action.payload.data
        state.successListPartnerDropdown = true
      } else {
        state.error = action.payload.error
        state.successListPartnerDropdown = false
      }
      state.getListPartnerDropdown = false
    })
    .addCase(resetListPartnerDropdown, (state) => {
      state.listPartnerDropdown = {}
      state.successListPartnerDropdown = false
      state.getListPartnerDropdown = false
      state.error = null
    })
}

export { getListPartnerDropdownAsync, getListPartnerDropdownExtraReducers, resetListPartnerDropdown }
