import * as React from 'react'
import i18n from 'i18n'
import { NppTypography, NppModal } from '@ui/Components'

interface ModalDownloadCSVCardBlockProps {
  showModal: boolean
  isMasterPartner: boolean
  onCloseModal?: () => void
  onClickConfirmationModal?: () => void
  description: string
  selectedPartnerText: string
  lastUpdateTime: string
}

export default function ModalDownloadCSVCardBlock({
  showModal = false,
  isMasterPartner = false,
  onCloseModal = null,
  onClickConfirmationModal = null,
  description,
  selectedPartnerText = '',
  lastUpdateTime = ''
}: ModalDownloadCSVCardBlockProps) {
  const { t } = i18n

  return (
    <NppModal
      title={t('reporting_dashboard.modal_download_csv.title')}
      open={showModal}
      onClose={onCloseModal}
      button={[
        {
          text: t('reporting_dashboard.modal_download_csv.cancel_button'),
          variant: 'outlined',
          onClick: () => onCloseModal()
        },
        {
          text: t('reporting_dashboard.modal_download_csv.confirmation_button'),
          variant: 'contained',
          onClick: () => onClickConfirmationModal()
        }
      ]}>
      <div className="ModalDownloadCSVCardBlock__modal-content">
        <NppTypography variant="subtitle1" wrap>
          {description}
        </NppTypography>
        <div className="ModalDownloadCSVCardBlock__modal-content-list">
          {isMasterPartner && (
            <div className="ModalDownloadCSVCardBlock__modal-content-list-item">
              <NppTypography variant="subtitle1">
                {t('reporting_dashboard.modal_download_csv.partner')}
              </NppTypography>
              <NppTypography variant="subtitle1">{selectedPartnerText}</NppTypography>
            </div>
          )}
          <div className="ModalDownloadCSVCardBlock__modal-content-list-item">
            <NppTypography variant="subtitle1">
              {t('reporting_dashboard.modal_download_csv.last_update_time')}
            </NppTypography>
            <NppTypography variant="subtitle1">{lastUpdateTime}</NppTypography>
          </div>
        </div>
        <NppTypography variant="subtitle1" wrap>
          {t('reporting_dashboard.modal_download_csv.confirmation_text')}
        </NppTypography>
      </div>
    </NppModal>
  )
}
