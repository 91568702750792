import * as React from 'react'

export default function useSetDocumentTitle(title) {
  React.useEffect(() => {
    document.title = title

    return () => {
      document.title = 'Partner Portal'
    }
  }, [])
}
