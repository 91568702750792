import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getOrganizationsApi } from "@services/ApiServices";

interface getOrganizationsApiData {
  data: object;
  error: string | null;
}

interface PayloadInterface {
  partner_ids?: string[]
  start_date?: string
  end_date?: string
}

const getOrganizationsAsync = createAsyncThunk(
  "ReportingDashboard/getOrganizations",
  async (payload: PayloadInterface, { dispatch }) => {
    const data = await getOrganizationsApi({ ...payload });
    return data as getOrganizationsApiData;
  }
);

const resetOrganizations = createAction("ReportingDashboard/resetOrganizations");

const getOrganizationExtraReducers = (builder: any) => {
  builder
    .addCase(getOrganizationsAsync.pending, (state) => {
      state.getOrganizations = true;
    })
    .addCase(getOrganizationsAsync.rejected, (state) => {
      state.getOrganizations = false;
    })
    .addCase(getOrganizationsAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.organizations = action.payload.data.data;
      } else {
        state.error = action.payload.error;
      }
      state.getOrganizations = false;
    })
    .addCase(resetOrganizations, (state) => {
      state.organizations = {};
      state.getOrganizations = false;
      state.error = null;
    });
};

export { getOrganizationsAsync, getOrganizationExtraReducers, resetOrganizations };
