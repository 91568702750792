import { useState, Dispatch, SetStateAction } from 'react'
import BaseSetting from '@config/BaseSetting'
import { parseNumber } from '@utilities/index'

const usePageNumber = (): [number, Dispatch<SetStateAction<number>>] => {
  const [number, setNumber] = useState<number>(parseNumber(BaseSetting.paginationPerPageDefault))

  const setPageNumber = (value: number): void => {
    setNumber(value)
  }

  return [number, setPageNumber]
}

export default usePageNumber
