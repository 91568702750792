import { useEffect } from 'react'
import { clearAlert, setAlert } from '@features/application/applicationSlice'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import { useLocation } from 'react-router-dom'
import ClientRoutes from 'bundles/config/Routes/WebClientRoutes'

const success = 'success'
const error = 'error'

const usePageAlert = () => {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const { alert } = useAppSelector((state) => state.application)

  const closeAlert = () => {
    dispatch(clearAlert())
  }

  const isAlertOpen = () => {
    return alert?.type
  }

  const updateAlert = (payload) => {
    if (
      !alert ||
      alert?.type !== payload?.type ||
      alert?.message !== payload?.message ||
      alert?.heading !== payload?.heading
    )
      dispatch(setAlert({ ...payload, path: location.pathname }))
  }

  const updateSuccessAlert = (payload) => {
    updateAlert({ ...payload, type: success })
  }

  const updateErrorAlert = (payload) => {
    updateAlert({ ...payload, type: error })
  }

  useEffect(() => {
    // will remove the alert
    // if alert is exist
    // and switch between page
    // should not close the alert when changing url from backup_summary_report#new to another url
    return () => {
      if(location.pathname === ClientRoutes.createReportsBackupSummaryPath()) {
        return
      }
      closeAlert()
    }
  }, [])

  return {
    isAlertOpen: isAlertOpen(),
    alert,
    closeAlert,
    updateAlert,
    updateSuccessAlert,
    updateErrorAlert
  }
}

export default usePageAlert
