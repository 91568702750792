import * as React from 'react'
import i18n from 'i18n'
import { NppAlert, NppTypography } from '@ui/Components'
import { usePageAlert } from '@hooks/index'

// Define an interface for the alert object
interface Alert {
  type: 'error' | 'warning' | 'info' | 'success' // Assuming these are the possible types
  heading: string
  message: string
  html?: boolean
}

export default function AlertPageSection({ alertType = '', alertMessage = '' }) {
  const { t } = i18n
  const { isAlertOpen, alert, closeAlert } = usePageAlert()

  if (!isAlertOpen && !alertType && !alertMessage) return null

  if (alertType && alertMessage)
    return (
      <NppAlert type={alertType}>
        <NppTypography variant="caption">{alertMessage}</NppTypography>
      </NppAlert>
    )

  // TypeScript will now be aware of the structure of `alert`
  return (
    <NppAlert type={alert.type} headingText={alert.heading} onClose={() => closeAlert()}>
      {alert.html && alert.message && (
        <p
          dangerouslySetInnerHTML={{
            __html: alert.message
          }}
        />
      )}

      {!alert.html && alert.message && <NppTypography wrap>{alert.message}</NppTypography>}
    </NppAlert>
  )
}
