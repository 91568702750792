import * as React from 'react'
import Box from '@mui/material/Box'
import { NppTypography, NppCheckbox, NppDropdown, NppTooltip } from '@ui/Components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Skeleton from '@mui/material/Skeleton'
import { visuallyHidden } from '@mui/utils'
// import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable'

export interface TableColumn {
  id: number | string
  columnName: string
  numeric?: boolean
  label: string
  spaceOnly?: boolean
  size: string
  headCell?: string
  align?: 'center' | 'right' | 'left' | 'inherit' | 'justify'
  withColor?: boolean
  noPadding?: boolean
  noDivider?: boolean
  orderable?: boolean
  component?: 'th' | 'td'
  allowColspan?: boolean
  tooltipText?: string
  wrap?: boolean
}

export interface TableRowData {
  id: number | string
  isLink?: boolean
  component?: string
  label?: string
  heightSize?: string
  active?: boolean
  colspan?: number
  summaryRow?: boolean
  [key: string]: string | number | boolean | React.ReactNode
}

interface NppSimpleDataTableProps {
  sx?: Record<string, unknown> | null
  header: TableColumn[]
  rows: TableRowData[]
  onClickRow: (row: TableRowData) => void
  order?: 'asc' | 'desc'
  setOrder?: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>
  orderBy?: string
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  stickyHeader?: boolean
  sxTableContainer?: Record<string, unknown>
  fullBorder?: boolean
  className?: string
  selected?: number[]
  setSelected?: React.Dispatch<React.SetStateAction<number[] | number | string[] | string>>
  listSortOptions?: string[]
  selectedDropdown?: {
    icon: React.ReactNode
    color: string
  }
  onDropdownSelect?: (selected: any, listId: number[] | string[] | (string | number)[]) => void
  withCheckboxSelection: boolean
  headerWrap?: boolean
}

interface EnhancedTableHeadProps {
  header: TableColumn[]
  order: 'asc' | 'desc'
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  loading: boolean
  listSortOptions: string[]
  selectedDropdown: NppSimpleDataTableProps['selectedDropdown']
  onDropdownSelect: NppSimpleDataTableProps['onDropdownSelect']
  listId: number[] | string[] | (string | number)[]
  withCheckboxSelection: boolean
  wrap: boolean
}

const sizes = ['icon', 'xxs', 'xs', 'sm', 'md', 'lg']

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  header,
  order,
  orderBy,
  onRequestSort,
  loading,
  listSortOptions,
  selectedDropdown,
  onDropdownSelect,
  listId,
  withCheckboxSelection,
  wrap
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) =>
    onRequestSort(event, property)

  const SolidChevronLeftIcon = () => <i className="solid-sort-table" />

  return (
    <TableHead>
      <TableRow>
        {withCheckboxSelection && (
          <TableCell align="center" padding="none" sortDirection={false} className="NppTable__cell-size-xxs">
            <NppDropdown
              menuItems={listSortOptions}
              onClickItem={(selected) => onDropdownSelect(selected, listId)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              leftIcon={selectedDropdown?.icon}
              className="NppTable__dropdown-checkbox"
              buttonClassName={`NppTable__dropdown-button-${selectedDropdown?.color}`}
            />
          </TableCell>
        )}
        {header.map((headCell, index) => (
          <TableCell
            key={`HeadCell-${index}`}
            align={headCell.align}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
            className={`NppTable__cell-size-${sizes?.includes(headCell?.size) ? headCell.size : 'costume'} ${
              headCell.withColor ? 'NppTable__cell-with-color' : ''
            } ${headCell.noPadding ? 'NppTable__cell-no-padding' : ''} ${wrap ? 'NppTable__cell-wrap' : ''}`}
            style={!sizes?.includes(headCell?.size) ? { width: headCell?.size } : {}}>
            <div
              className={`NppTable__head-label NppTable__head-label-${headCell.noDivider ? 'no-divider' : ''}`}>
              {headCell.orderable ? (
                <TableSortLabel
                  disabled={loading}
                  active={orderBy === headCell.columnName}
                  direction={orderBy === headCell.columnName ? order : 'asc'}
                  onClick={createSortHandler(headCell.columnName)}
                  IconComponent={SolidChevronLeftIcon}>
                  <div className="NppTable__head-label-text">
                    {headCell.label}
                    {headCell?.tooltipText?.length > 0 ? (
                      <NppTooltip title={headCell?.tooltipText}>
                        <i className="solid-alert-question" />
                      </NppTooltip>
                    ) : null}
                  </div>
                  {orderBy === headCell.columnName && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  )}
                </TableSortLabel>
              ) : (
                <>
                  <NppTypography component="span">{headCell.label}</NppTypography>
                  {headCell?.tooltipText?.length > 0 ? (
                    <NppTooltip title={headCell?.tooltipText}>
                      <i className="solid-alert-question" />
                    </NppTooltip>
                  ) : null}
                </>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const NppSimpleDataTable: React.FC<NppSimpleDataTableProps> = ({
  sx,
  header,
  rows,
  onClickRow,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  loading,
  stickyHeader,
  sxTableContainer,
  fullBorder,
  className,
  selected,
  setSelected,
  listSortOptions,
  selectedDropdown,
  onDropdownSelect,
  withCheckboxSelection,
  headerWrap
}) => {
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //   const { selectAllConst } = useCheckboxTable()

  const widthSkeleton = (index: number): string => {
    if (index % 3 === 0) return '72%'
    if (index % 2 === 0) return '64%'
    return '84%'
  }

  const renderRows = (): React.ReactNodeArray => {
    if (loading) {
      const loadingRows = rows.length > 3 ? rows : Array.from({ length: 5 })

      return loadingRows.map((row, index) => (
        <TableRow tabIndex={-1} key={`LoadingRow-${index}`}>
          {withCheckboxSelection && (
            <TableCell key={`LoadingCell-${index}`} align="left">
              <Skeleton variant="text" height={22} width="100%" />
            </TableCell>
          )}
          {header.map((head, headerIndex) => (
            <TableCell
              key={`LoadingCell-${headerIndex}`}
              align={head.align || 'left'}
              className={`NppTable__cell-size-${head.size} ${
                head.withColor ? 'NppTable__cell-with-color' : ''
              } ${head.noPadding ? 'NppTable__cell-no-padding' : ''} `}>
              <Skeleton
                variant="text"
                height={22}
                width={head.size === 'icon' ? '100%' : widthSkeleton(index)}
              />
            </TableCell>
          ))}
        </TableRow>
      ))
    } else {
      return rows.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`
        const isRowChecked = false
        //   (selected.includes(selectAllConst) && !selected.includes(row.id)) ||
        //   (!selected.includes(selectAllConst) && selected.includes(row.id))

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={`ContentRow-${index}`}
            className={`${row.isLink ? 'NppTableRow__linking' : ''} NppTableCell__height-size-${
              row.heightSize
            } ${row.active ? 'NppTableRow__active' : ''}
            ${row.isLink && row.active ? 'NppTableRow__active-link' : ''} ${
              !(row.isLink && row.active) && isRowChecked && !row.summaryRow ? 'NppTableRow__selected' : ''
            }`}>
            {withCheckboxSelection && !(row.isLink && row.active) && (
              <TableCell
                key={`ContentCell-${labelId}`}
                component="td"
                id={labelId}
                align="left"
                scope=""
                colSpan={null}>
                {!(row.colspan && row.colspan > 0) && (
                  <NppCheckbox checked={isRowChecked} onChange={() => setSelected(row.id)} />
                )}
              </TableCell>
            )}
            {header.map((h, hIndex) => {
              if (row[h.columnName] === '{{colspan}}') return null

              return (
                <TableCell
                  key={`ContentCell-${hIndex}`}
                  component={
                    typeof row.component === 'string' || typeof h.component === 'string'
                      ? 'td'
                      : row.component || h.component
                  }
                  onClick={() => onClickRow(row)}
                  id={labelId}
                  align={h.align || 'left'}
                  scope={row.component === 'th' ? 'row' : ''}
                  colSpan={h.allowColspan && row.colspan ? row.colspan : undefined}
                  className={`${h.withColor ? 'NppTable__cell-with-color' : ''}
                ${h.noPadding ? 'NppTable__cell-no-padding' : ''}
                ${h.wrap ? 'NppTable__cell-wrap' : ''}
                  NppTable__cell-size-${h.size}`}
                  style={!sizes?.includes(h?.size) ? { maxWidth: h?.size } : {}}>
                  {row[h.columnName]}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })
    }
  }

  return (
    <div className={`NppTable__wrapper ${fullBorder ? 'NppTable__wrapper-full-border' : ''} ${className}`}>
      <TableContainer sx={{ ...sxTableContainer }}>
        <Table stickyHeader={stickyHeader} sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            header={header}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            loading={loading}
            withCheckboxSelection={withCheckboxSelection}
            listSortOptions={listSortOptions}
            selectedDropdown={selectedDropdown}
            onDropdownSelect={onDropdownSelect}
            listId={rows.map((x) => x.id).filter((x) => x !== undefined)}
            wrap={headerWrap}
          />
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

NppSimpleDataTable.defaultProps = {
  sx: null,
  header: [],
  rows: [],
  onClickRow: () => {},
  order: 'asc',
  setOrder: () => {},
  orderBy: '',
  setOrderBy: () => {},
  loading: false,
  stickyHeader: false,
  sxTableContainer: {},
  fullBorder: false,
  className: '',
  selected: [],
  setSelected: () => {},
  listSortOptions: [],
  selectedDropdown: { icon: undefined, color: null },
  onDropdownSelect: () => {},
  withCheckboxSelection: false
}

export default NppSimpleDataTable
