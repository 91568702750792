import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  getProtectionCoverageAsync,
  getProtectionCoverageExtraReducers
} from './serviceActions/getProtectionCoverageAysnc'

import { getSeatUsageAsync, getSeatUsageExtraReducers } from './serviceActions/getSeatUsageAysnc'

import { getOrganizationsAsync, getOrganizationExtraReducers } from './serviceActions/getOrganizationsAysnc'

import { getAccountsAsync, getAccountsExtraReducers } from './serviceActions/getAccountsAysnc'

import { getBillingAsync, getBillingExtraReducers } from './serviceActions/getBillingAysnc'

interface LoadingState {
  getProtectionCoverage?: boolean;
  getSeatUsage?: boolean;
  getOrganizations?: boolean;
  getAccounts?: boolean;
  getJobStatus?: boolean;
  getBilling?: boolean;
}

interface ReportingDashboardState {
  protectionCoverage: object
  getProtectionCoverage: boolean
  seatUsage: object
  getSeatUsage: boolean
  organizations: object
  getOrganizations: boolean
  accounts: object
  getAccounts: boolean
  billing: object
  getBilling: boolean
  error: null | string
  isLoading: boolean
  loadingStatus: LoadingState
}

const initialState: ReportingDashboardState = {
  protectionCoverage: {},
  getProtectionCoverage: true,
  seatUsage: {},
  getSeatUsage: true,
  organizations: {},
  getOrganizations: true,
  accounts: {},
  getAccounts: true,
  billing: {},
  getBilling: true,
  error: null,
  isLoading: false,
  loadingStatus: {
    getProtectionCoverage: false,
    getSeatUsage: false,
    getOrganizations: false,
    getAccounts: false,
    getJobStatus: false,
    getBilling: false
  }
}

const reportingDashboardSlice = createSlice({
  name: 'reportingDashboard',
  initialState,
  reducers: {
    resetProtectionCoverage(state) {
      state.protectionCoverage = initialState.protectionCoverage
      state.getProtectionCoverage = initialState.getProtectionCoverage
      state.error = initialState.error
    },
    resetSeatUsage(state) {
      state.seatUsage = initialState.seatUsage
      state.getSeatUsage = initialState.getSeatUsage
      state.error = initialState.error
    },
    resetOrganizations(state) {
      state.organizations = initialState.organizations
      state.getOrganizations = initialState.getOrganizations
      state.error = initialState.error
    },
    resetAccounts(state) {
      state.accounts = initialState.accounts
      state.getAccounts = initialState.getAccounts
      state.error = initialState.error
    },
    resetBilling(state) {
      state.billing = initialState.billing
      state.getBilling = initialState.getBilling
      state.error = initialState.error
    },
    setLoadingStatus(state, action: PayloadAction<LoadingState>) {
      state.loadingStatus = { ...state.loadingStatus, ...action.payload }
      state.isLoading = Object.values(state.loadingStatus).every(value => value === true)
    }
  },
  extraReducers: (builder) => {
    getProtectionCoverageExtraReducers(builder)
    getSeatUsageExtraReducers(builder)
    getOrganizationExtraReducers(builder)
    getAccountsExtraReducers(builder)
    getBillingExtraReducers(builder)
    // Add other extraReducers here if needed
  }
})

export { getProtectionCoverageAsync, getSeatUsageAsync, getOrganizationsAsync, getAccountsAsync, getBillingAsync }
export const { resetProtectionCoverage, resetSeatUsage, resetOrganizations, resetAccounts, resetBilling, setLoadingStatus } = reportingDashboardSlice.actions
export default reportingDashboardSlice.reducer
