import WebApiRoutes from '@config/Routes/WebApiRoutes'
import ServiceClientFactory from '@libs/ServiceClient'

const webClientApi = () => {
  return new ServiceClientFactory({}).create()
}

const ReportingDashboardService = {
  getProtectionCoverageApi: (payload: { csrfToken?: string; partner_ids?: string[] }) => {
    const { csrfToken, partner_ids } = payload
    return webClientApi().postRequest({
      url: WebApiRoutes.protectionCoveragePath(),
      parameters: { partner_ids: partner_ids },
      config: { headers: { 'X-CSRF-Token': csrfToken } }
    })
  },
  getSeatUsageApi: (payload: { csrfToken?: string; partner_ids?: string[] }) => {
    const { csrfToken, partner_ids } = payload
    return webClientApi().postRequest({
      url: WebApiRoutes.seatUsagePath(),
      parameters: { partner_ids: partner_ids },
      config: { headers: { 'X-CSRF-Token': csrfToken } }
    })
  },
  getOrganizationsApi: (payload: {
    start_date?: string
    end_date?: string
  }) => {
    const { start_date, end_date } = payload
    return webClientApi().getRequest({
      url: WebApiRoutes.organizationPath({start_date, end_date})
    })
  },
  getAccountsApi: (payload: {
    start_date?: string
    end_date?: string
  }) => {
    const { start_date, end_date } = payload
    return webClientApi().getRequest({
      url: WebApiRoutes.accountPath({start_date, end_date})
    })
  },
  getProtectionCoverageReportApi: (
    payload: {
      page?: number
      sort_column?: number
      sort_direction?: string
      partner_ids?: string[]
      csrfToken?: string
      page_size?: number
      page_action?: string
      pagination_state?: string
    } = {}
  ) => {
    const { page, sort_column, sort_direction, page_size, csrfToken, page_action, pagination_state } = payload
    return webClientApi().postRequest({
      url: WebApiRoutes.protectionCoverageReportPath({
        page,
        sort_column,
        sort_direction,
        page_size,
        page_action,
        pagination_state
      }),
      parameters: { partner_ids: payload?.partner_ids },
      config: { headers: { 'X-CSRF-Token': csrfToken } }
    })
  },
  getSeatUsageReportApi: (
    payload: {
      page?: number
      sort_column?: number
      sort_direction?: string
      partner_ids?: string[]
      csrfToken?: string
      page_size?: number
      page_action?: string
      pagination_state?: string
    } = {}
  ) => {
    const { page, sort_column, sort_direction, page_size, csrfToken, page_action, pagination_state } = payload
    return webClientApi().postRequest({
      url: WebApiRoutes.seatUsageReportPath({
        page,
        sort_column,
        sort_direction,
        page_size,
        page_action,
        pagination_state
      }),
      parameters: { partner_ids: payload?.partner_ids },
      config: { headers: { 'X-CSRF-Token': csrfToken } }
    })
  },
  getListPartnerDropdownApi: (payload = {}) => {
    return webClientApi().getRequest({
      url: WebApiRoutes.listPartnerDropdownPath(payload)
    })
  },
  getBillingApi: (payload = {}) => {
    return webClientApi().getRequest({
      url: WebApiRoutes.billingPath(payload)
    })
  },
  setListPartnerDropdownApi: (payload: {
    all_partner?: number
    authenticity_token?: string
    partners?: string[]
    path?: string
  } = {}) => {
    return webClientApi().putRequest({
      url: WebApiRoutes.setListPartnerDropdownPath(),
      parameters: { ...payload},
      config: { headers: { 'X-CSRF-Token': payload.authenticity_token } }
    })
  },
  downloadCSVReportProtectionCoverageApi: (payload: {
    criteria?: string
    file_type?: string
  } = {}) => {
    const {criteria, file_type} = payload
    return webClientApi().getRequest({
      url: WebApiRoutes.downloadCSVReportProtectionCoveragePath({criteria, file_type}),
    })
  },

  downloadCSVReportSeatUsageApi: (payload: {
    criteria?: string
    file_type?: string
  } = {}) => {
    const {criteria, file_type} = payload
    return webClientApi().getRequest({
      url: WebApiRoutes.downloadCSVReportSeatUsagePath({criteria, file_type}),
    })
  },
}

export default ReportingDashboardService
