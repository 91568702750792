import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ReportingDashboardPage, ProtectionCoverageReport, SeatUsageReport, CreateBackupSummaryReport, BackupSummaryReport } from '@pages/index'
import {
  rootPath,
  reportingDashboardPath,
  protectionCoverageReportPath,
  seatUsageReportPath,
  reportsBackupSummaryPath,
  createReportsBackupSummaryPath
} from 'bundles/config/Routes/WebClientRoutes'

export default (
  <Routes>
    <Route path={rootPath} element={<ReportingDashboardPage />} />
    <Route path={reportingDashboardPath} element={<ReportingDashboardPage />} />
    <Route path={protectionCoverageReportPath} element={<ProtectionCoverageReport />} />
    <Route path={seatUsageReportPath} element={<SeatUsageReport />} />
    <Route path={createReportsBackupSummaryPath} element={<CreateBackupSummaryReport />} />
    <Route path={reportsBackupSummaryPath} element={<BackupSummaryReport />} />
  </Routes>
)
