import { useEffect, useState } from 'react'
import i18n from 'i18n'
import { useLocation } from 'react-router-dom'
import { pushSpliceEntry, filterArrayByValue, filterArrayByKey, sliceArrayWithArray } from '@utilities/index'
import { useLocalStorage, useSearchDelay } from '@hooks/index'
import { constant } from '@config/BaseSetting'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import {
  setListPartnerDropdownAsync,
  resetSetListPartnerDropdown,
  setPartnerIds
} from '@features/listPartnerDropdown/listPartnerDropdownSlice'
import { useGetListPartnerDropdownQuery } from 'bundles/features/listPartnerDropdown/services/listPartnerApi'

export default function usePartnerDropdown() {
  const { t } = i18n
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { submitPartner } = useAppSelector((state) => state.listPartnerDropdown)
  const { success } = submitPartner
  const { data, isSuccess: successListPartnerDropdown } = useGetListPartnerDropdownQuery()
  
  //Active partner filter from local storage
  const [activePartnerFilter, setActivePartnerFilter] = useLocalStorage<any[]>(constant.PARTNER_FILTER_STORAGE)
  const [listPartnerDropdown, setListPartnerDropdown] = useState(activePartnerFilter)
  const [selectedPartnerFilter, setSelectedPartnerFilter] = useState(activePartnerFilter ?? [])
  const [selected, setSelected] = useState(activePartnerFilter ?? [])
  // Selected Ids that will be send as parameter
  const [selectedIds, setSelectedIds] = useState([])
  // Sea value
  const [searchValue, setSearchValue] = useState('')
  // List All partner
  const [listPartnerDefault, setListPartnerDefault] = useState(listPartnerDropdown ?? [])
  // List partner after searched
  const [listPartnerSearched, setListPartnerSearched] = useState([])

  const getTotalSelected = (): number => {
    const selectedCount = selectedPartnerFilter?.length ?? 0
    const listCount = listPartnerDefault?.length ?? 0

    if (selectedPartnerFilter?.includes('all')) {
      return selectedCount > 1 ? listCount - (selectedCount - 1) : listCount
    }

    return selectedCount
  }

  const getTotalSelectedText = (): string => {
    const totalSelected = getTotalSelected()
    const text: string =
      totalSelected > 0 && totalSelected !== listPartnerDefault?.length
        ? t('reporting_dashboard.partner_dropdown.partner_selected', {
            selected: totalSelected
          })
        : t('reporting_dashboard.partner_dropdown.all_partner')

    return text
  }

  const onSearchDropdown = (value): any => {
    const filteredDropdown = filterArrayByValue(listPartnerDefault, value, 'organization_name')
    if (filteredDropdown) {
      setListPartnerSearched(filteredDropdown)
    }
  }

  const handleSetListPartner = (payload) => {
    dispatch(setListPartnerDropdownAsync(payload)).then(() => {
      setSelectedIds(payload.partners)
      dispatch(setPartnerIds(payload.partners))
      dispatch(resetSetListPartnerDropdown())
    })
  }

  const onSetSelectedIds = (ids, setSelectedPartner = false) => {
    const isAllSelected = ids?.includes('all')

    // Prepare a base list of IDs from listPartnerDefault if 'all' is selected
    let baseIds = isAllSelected ? filterArrayByKey(listPartnerDefault, 'id') : []

    // If 'all' is selected and there are additional selected items,
    // remove the extra selected items from the base list of IDs
    if (isAllSelected && ids?.length > 1) {
      baseIds = sliceArrayWithArray(baseIds, ids)
    }
    
    if (setSelectedPartner) {
      handleSetListPartner({
        all_partner: isAllSelected,
        partners: isAllSelected ? baseIds : ids,
        path: location.pathname
      })
    }
  }

  const onFilterDropdown = () => {
    setSelectedPartnerFilter(selected)
    setActivePartnerFilter(selected)
    onSetSelectedIds(selected, true)
  }

  const onSelectItem = (id) => {
    const selectedData = pushSpliceEntry(selected, id)
    const isSelectingAll = !selectedData?.includes('all') && selectedData?.length === listPartnerDefault?.length
    const isDeselectingAll =
      selectedData?.includes('all') && selectedData?.length === listPartnerDefault?.length + 1

    const newSelectedState = isSelectingAll ? ['all'] : isDeselectingAll ? [] : selectedData

    setSelected(newSelectedState)
  }

  useEffect(() => {
    if (successListPartnerDropdown && data) {
      setListPartnerDropdown(data)
    }
  }, [successListPartnerDropdown])

  useEffect(() => {
    const activePartners = filterArrayByValue(listPartnerDropdown, true, 'selected')
    const isSelectingAll = activePartners.length === listPartnerDropdown?.length
    const filterResult = isSelectingAll ? ['all'] : filterArrayByKey(activePartners, 'id')

    setSelected(filterResult)
    setSelectedPartnerFilter(filterResult)
    setActivePartnerFilter(filterResult)
    onSetSelectedIds(filterResult)
    setListPartnerDefault(listPartnerDropdown ?? [])
  }, [listPartnerDropdown])

  useSearchDelay(
    {
      actionFunc: () => {
        onSearchDropdown(searchValue)
      },
      resetFunc: () => {
        setSearchValue('')
      },
      onChangeKeyword: () => {
        setSearchValue(searchValue)
      }
    },
    searchValue
  )

  return {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    listPartnerSearched,
    listPartnerDefault,
    activePartnerFilter,
    getTotalSelected,
    onSearchDropdown,
    onFilterDropdown,
    onSelectItem,
    selectedPartnerFilter,
    selectedIds,
    totalSelectedText: getTotalSelectedText(),
    setSelectedPartnerSuccess: success,
    successListPartnerDropdown
  }
}
