import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import logger from 'redux-logger'
import { combineReducers } from 'redux'

// Import your reducers and adjust the paths accordingly
import protectionCoverageReportReducer from '@features/protectionCoverageReport/protectionCoverageReportSlice'
import seatUsageReportReducer from '@features/seatUsageReport/seatUsageReportSlice'
import listPartnerDropdownReducer from '@features/listPartnerDropdown/listPartnerDropdownSlice'
import applicationReducer from '@features/application/applicationSlice'
import { reportingDashboardReducer } from '@features/reportingDashboard'
import { listOrganizationDropdownReducer } from '@features/listOrganizationDropdown'
import { listOrganizationApi } from '@features/listOrganizationDropdown'
import { reportingDashboardApi } from '@features/reportingDashboard'
import { listPartnerApi } from '@features/listPartnerDropdown/services/listPartnerApi'
import { bsrRequestApi } from 'bundles/features/bsrRequestApi'
import { bsrRequestItemApi } from 'bundles/features/bsrRequestItemApi'
import { accountApi } from 'bundles/features/accountApi'

const rootReducer = combineReducers({
  reportingDashboard: reportingDashboardReducer,
  protectionCoverageReport: protectionCoverageReportReducer,
  seatUsageReport: seatUsageReportReducer,
  listPartnerDropdown: listPartnerDropdownReducer,
  listOrganizationDropdown: listOrganizationDropdownReducer,
  application: applicationReducer,
  [reportingDashboardApi.reducerPath]: reportingDashboardApi.reducer,
  [listOrganizationApi.reducerPath]: listOrganizationApi.reducer,
  [listPartnerApi.reducerPath]: listPartnerApi.reducer,
  [bsrRequestApi.reducerPath]: bsrRequestApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [bsrRequestItemApi.reducerPath]: bsrRequestItemApi.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .prepend()
      // Chain prepend and concat calls
      .concat(
        // Add conditional middleware for development environment
        process.env.NODE_ENV !== 'production' ? [logger] : []
      )
      .concat(reportingDashboardApi.middleware)
      .concat(listOrganizationApi.middleware)
      .concat(listPartnerApi.middleware)
      .concat(bsrRequestApi.middleware)
      .concat(accountApi.middleware)
      .concat(bsrRequestItemApi.middleware),
  devTools: process.env.NODE_ENV !== 'production'
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
