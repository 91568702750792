import * as React from 'react'
import { ReactNode, CSSProperties, Ref } from 'react'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { ErrorRounded as ErrorRoundedIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import { NppTypography } from '@ui/Components'
import { bgIconSuccess, bgIconError } from '@config/themes/default/variables'

interface NppAlertProps {
  sx?: CSSProperties
  className?: string
  children: ReactNode | ReactNode[]
  type?: 'success' | 'error' | 'info' | any
  headingText?: string
  innerRef?: Ref<HTMLDivElement>
  icon?: React.ReactNode
  spacing?: boolean
  [key: string]: any // To capture other props
}

const error: 'error' = 'error'
const success: 'success' = 'success'
const info: 'info' = 'info'

const NppAlert: React.FC<NppAlertProps> = ({
  sx,
  className = '',
  children,
  type = 'success',
  headingText,
  innerRef,
  spacing,
  ...rest
}: NppAlertProps) => {
  const icon = () => {
    if (rest.icon) return rest.icon
    if (type === error) {
      return <ErrorRoundedIcon sx={{ color: bgIconError, fontSize: '2.4rem' }} />
    }
    if (type === success) {
      return <i className="solid-alert-check" />
    }
    if (type === info) {
      return <i className="NppAlert-icon solid-alert-info" />
    }

    return null
  }

  const styles: CSSProperties = {
    marginBottom: '2rem'
  }

  return (
    <Alert
      {...rest}
      icon={icon()}
      severity={type}
      ref={innerRef}
      className={`NppAlert__wrapper ${className} ${!headingText && 'NppAlert__without-heading'}  ${
        !spacing && 'NppAlert__without-margin'
      }`}
      sx={{ ...sx, ...styles }}>
      {headingText && <AlertTitle>{headingText}</AlertTitle>}
      {children}
    </Alert>
  )
}

NppAlert.defaultProps = {
  type: 'success',
  headingText: undefined,
  children: '',
  sx: {},
  className: '',
  spacing: false,
  innerRef: () => {}
}

export default NppAlert
