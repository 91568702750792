import * as React from "react";
import useActiveCharts from "../hooks/useActiveChart";

const ChartSection = () => {
  const { activeCharts } = useActiveCharts();

  return (
    <div className="ReportingDashboard__content-wrapper">

      {activeCharts.map((_, index) => {
        if (index % 2 === 0) {
          return (
            <div className="ReportingDashboard__card-wrapper" key={index}>
              {activeCharts[index].component}
              {activeCharts[index + 1] && activeCharts[index + 1].component}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default ChartSection;