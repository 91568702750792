import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getPartnerTypeApi } from '@services/ApiServices'

interface getPartnerTypeApiData {
  data: []
  error: string | null
}

const getPartnerTypeAsync = createAsyncThunk('Application/getPartnerType', async () => {
  const data = await getPartnerTypeApi()
  return data as getPartnerTypeApiData
})

const resetPartnerTypeDropdown = createAction('Application/resetPartnerTypeDropdown')

const getPartnerTypeExtraReducers = (builder: any) => {
  builder
    .addCase(getPartnerTypeAsync.pending, (state) => {
      state.getPartnerType = true
    })
    .addCase(getPartnerTypeAsync.rejected, (state) => {
      state.getPartnerType = false
    })
    .addCase(getPartnerTypeAsync.fulfilled, (state, action) => {
      if (!action.payload.error) {
        state.partnerType = action.payload.data
        state.isMasterPartner = state.partnerType === 'Partner'
      } else {
        state.error = action.payload.error
      }
      state.getPartnerType = false
    })
    .addCase(resetPartnerTypeDropdown, (state) => {
      state.partnerType = {}
      state.getPartnerType = false
      state.error = null
    })
}

export { getPartnerTypeAsync, getPartnerTypeExtraReducers, resetPartnerTypeDropdown }
