import * as React from 'react'
import i18n from 'i18n'
import { NppButton } from '@ui/Components'
import PartnerDropdownBlock from '@ui/Blocks/Shared/DropdownBlock/PartnerDropdownBlock'
import useMasterPartner from 'bundles/features/listPartnerDropdown/hooks/useMasterPartner'
import { useAppSelector } from 'bundles/hooks'

interface PartnerHeaderSectionProps {
  isMasterPartner?: boolean
  isLoading?: boolean
  selected: string[]
  setSelected: (selected: string[]) => void
  searchValue: string
  setSearchValue: (string) => void
  getTotalSelected: () => number
  onFilterDropdown: () => void
  onSelectItem: (string) => void
  listPartnerSearched: { id; organization_name }[]
  listPartnerDefault: { id; organization_name }[]
  totalSelectedText: string
  showAddPartner?: boolean
}

const PartnerHeaderSection = ({
  isMasterPartner,
  isLoading,
  selected,
  setSelected,
  searchValue,
  setSearchValue,
  getTotalSelected, 
  onFilterDropdown,
  onSelectItem,
  listPartnerSearched,
  listPartnerDefault,
  totalSelectedText,
  showAddPartner = false
}: PartnerHeaderSectionProps) => {
  const { t } = i18n
  const isMasterPartnerHook = useMasterPartner()
  const isLoadingHook = useAppSelector((state) => state.reportingDashboard.isLoading)

  isMasterPartner = isMasterPartner || isMasterPartnerHook
  isLoading = isLoading || isLoadingHook

  if (!isMasterPartner) return null

  return (
    <div className={`PartnerHeaderSection__header${isMasterPartner && !isLoading ? '-partner' : ''}`}>
      {!isLoading ? (
        <PartnerDropdownBlock
          selected={selected}
          setSelected={setSelected}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          getTotalSelected={getTotalSelected}
          onFilterDropdown={onFilterDropdown}
          onSelectItem={onSelectItem}
          listPartnerSearched={listPartnerSearched}
          listPartnerDefault={listPartnerDefault}
          dropdownText={totalSelectedText}
        />
      ) : null}
      {showAddPartner && !isLoading ? (
        <NppButton variant="contained" startIcon={<i className="solid-add" />} href="/partners/new">
          {t('reporting_dashboard.index.new_partner_button')}
        </NppButton>
      ) : null}
    </div>
  )
}

export default PartnerHeaderSection
