import { createSlice } from '@reduxjs/toolkit'

import {
  getSeatUsageReportAsync,
  getSeatUsageReportExtraReducers
} from './serviceActions/getSeatUsageReportAysnc'

import { downloadCSVReportAsync, downloadCSVReportExtraReducers } from './serviceActions/downloadCSVReportAsync'
interface SeatUsageReportState {
  seatUsageReport: object
  getSeatUsageReport: boolean
  error: null | string
  downloadCSVReport: object
}

const initialState: SeatUsageReportState = {
  seatUsageReport: {},
  getSeatUsageReport: true,
  error: null,
  downloadCSVReport: {}
}

const seatUsageReportSlice = createSlice({
  name: 'seatUsageReport',
  initialState,
  reducers: {
    resetSeatUsageReport(state) {
      state.seatUsageReport = initialState.seatUsageReport
      state.getSeatUsageReport = initialState.getSeatUsageReport
      state.error = initialState.error
    },
    resetDownloadCSVReport(state) {
      state.downloadCSVReport = initialState.downloadCSVReport
    }
  },
  extraReducers: (builder) => {
    getSeatUsageReportExtraReducers(builder)
    downloadCSVReportExtraReducers(builder)
    // Add other extraReducers here if needed
  }
})

export { getSeatUsageReportAsync, downloadCSVReportAsync }
export const { resetSeatUsageReport, resetDownloadCSVReport } = seatUsageReportSlice.actions
export default seatUsageReportSlice.reducer
