import { createSlice } from '@reduxjs/toolkit'

import {
  getProtectionCoverageReportAsync,
  getProtectionCoverageReportExtraReducers
} from './serviceActions/getProtectionCoverageReportAysnc'

import { downloadCSVReportAsync, downloadCSVReportExtraReducers } from './serviceActions/downloadCSVReportAsync'

interface ProtectionCoverageReportState {
  protectionCoverageReport: object
  getProtectionCoverageReport: boolean
  error: null | string
  downloadCSVReport: object
}

const initialState: ProtectionCoverageReportState = {
  protectionCoverageReport: {},
  getProtectionCoverageReport: true,
  error: null,
  downloadCSVReport: {}
}

const protectionCoverageReportSlice = createSlice({
  name: 'protectionCoverageReport',
  initialState,
  reducers: {
    resetProtectionCoverageReport(state) {
      state.protectionCoverageReport = initialState.protectionCoverageReport
      state.getProtectionCoverageReport = initialState.getProtectionCoverageReport
      state.error = initialState.error
    },
    resetDownloadCSVReport(state) {
      state.downloadCSVReport = initialState.downloadCSVReport
    }
  },
  extraReducers: (builder) => {
    getProtectionCoverageReportExtraReducers(builder)
    downloadCSVReportExtraReducers(builder)
    // Add other extraReducers here if needed
  }
})

export { getProtectionCoverageReportAsync, downloadCSVReportAsync }
export const { resetProtectionCoverageReport, resetDownloadCSVReport } = protectionCoverageReportSlice.actions
export default protectionCoverageReportSlice.reducer
