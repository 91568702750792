import useDateFilter from "bundles/hooks/useDateFilter";
import usePartnerDropdown from "bundles/pages/sharedHooks/usePartnerDropdown";
import { useEffect, useState } from "react";
import { useGetOrganizationsQuery } from "../services/reportingDashboardApi";
import { useAppDispatch } from "bundles/hooks";
import { setLoadingStatus } from "..";

const useOrganizationChart = () => {
  const dispatch = useAppDispatch()
  const { dateFilter, handleDateSelected } = useDateFilter({ startDate: '', endDate: '' })
  const { setSelectedPartnerSuccess } = usePartnerDropdown()
  const { data, isFetching, isSuccess, refetch } = useGetOrganizationsQuery({ start_date: dateFilter.startDate, end_date: dateFilter.endDate });
  const [ chartData, setChartData ] = useState({ 
    values: [],
    types: ['area-spline', 'area-spline', 'area-spline'],
    colors: ['#E2A208', '#BA1C31', '#0075E1']
  })

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(chartData => ({
        ...chartData,
        values: [data?.labels, data?.suspended, data?.unsubscribed, data?.active]
      }));
    }
  }, [isSuccess, data]);
 
  useEffect(() => {
    if (setSelectedPartnerSuccess) refetch()
  }, [setSelectedPartnerSuccess])

  useEffect(() => {
    dispatch(setLoadingStatus({ getOrganizations: isFetching }))
  }, [isFetching])

  return {
    chartData,
    isFetching,
    handleDateSelected
  }
}

export default useOrganizationChart