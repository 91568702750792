import { createApi } from "@reduxjs/toolkit/query/react";
import WebApiRoutes from "bundles/config/Routes/WebApiRoutes";
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const listPartnerApi = createApi({
  reducerPath: "getListPartnerApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getListPartnerDropdown: builder.query<any, void>({
      query: () => ({
        url: WebApiRoutes.listPartnerDropdownPath(),
      }),
    }),
    getPartnerType: builder.query<any, void>({
      query: () => ({
        url: WebApiRoutes.partnerTypePath()
      }),
    }),
    setSelectedPartner: builder.mutation<any, any>({
      query: (data) => ({
        url: WebApiRoutes.setListPartnerDropdownPath(),
        method: 'PUT',
        headers: { 'X-CSRF-Token': data.csrfToken },
        body: {
          partners: data.partners,
          path: data.path
        }
      }),
    }),
  }),
});

export const { useGetListPartnerDropdownQuery, useGetPartnerTypeQuery, useSetSelectedPartnerMutation } = listPartnerApi;