import * as React from 'react'
import i18n from 'i18n'
import { NppDatePicker } from '@ui/Components'
import WidgetCardBlock from 'bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock'
import { accountsPath } from 'bundles/config/Routes/WebClientRoutes'
import useAccountChart from '../hooks/useAccountChart'

const AccountChart = () => {
  const { t } = i18n
  const { chartData, isFetching, handleDateSelected } = useAccountChart()

  return (
    <WidgetCardBlock
      title={t('reporting_dashboard.index.account_card.header')}
      description={'20 Feb 2024 at 10:49'}
      button={t('reporting_dashboard.index.account_card.report_detail_button')}
      actionButton={accountsPath}
      typeButton="href"
      isLoading={isFetching}
      rightHeader={
        <NppDatePicker onDateSelected={(startDate, endDate) => handleDateSelected(startDate.toString(), endDate.toString())} />
      }
      chartData={chartData}
      chartType="stackedBar"
      showValueChart
    />
  )
}

export default AccountChart
