import { useState } from 'react'

type DateFilter = {
  startDate: string
  endDate: string
}

const useDateFilter = (initialState: DateFilter) => {
  const [dateFilter, setDateFilter] = useState<DateFilter>(initialState)

  const handleDateSelected = (startDate: string, endDate: string) => {
    setDateFilter({ startDate, endDate })
  }

  return { dateFilter, handleDateSelected }
}

export default useDateFilter