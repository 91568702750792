import * as React from 'react'
import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import i18n from 'i18n'
import { NppTypography, NppButton, NppLink } from '@ui/Components'

interface ButtonProps {
  text?: string
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
  hidden?: boolean
  variant?: 'outlined' | 'contained'
  position?: 'left' | 'right'
  noPadding?: boolean
}

interface NppModalProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  className?: string
  header?: React.ReactNode
  title?: string
  footer?: React.ReactNode
  button?: ButtonProps | ButtonProps[] | string
  preventOutsideClick?: boolean
  sideContent?: React.ReactNode
  sideContentTitle?: string
  [key: string]: any
}

const NppModal: React.FC<NppModalProps> = ({
  open,
  onClose,
  children = null,
  className = '',
  header = null,
  title = '',
  footer = null,
  button = '',
  preventOutsideClick = false,
  ...rest
}) => {
  const { t } = i18n

  const onCloseModal = (e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent, reason?: string) => {
    if (!(reason === 'backdropClick' && preventOutsideClick)) {
      onClose()
    }
  }

  const renderHeader = (header, title) => {
    // Directly return the custom header if provided
    return (
      header || (
        <Box className="NppModal__header">
          <NppTypography id="modal-modal-title" variant="h3">
            {title}
          </NppTypography>
        </Box>
      )
    )
  }

  const renderBody = (children) => {
    // Return null immediately if no children
    if (!children) return null

    // Handle string children specifically or return general children
    const content =
      typeof children === 'string' ? <NppTypography id="modal-modal-body">{children}</NppTypography> : children

    return <Box className="NppModal__body">{content}</Box>
  }

  const getFooterClassNames = (button) => {
    let classNames = 'NppModal__footer '
    if (Array.isArray(button)) {
      classNames += button[0]?.position === 'right' ? 'NppModal__button-right ' : ''
      classNames += button[0]?.position === 'left' ? 'NppModal__button-left ' : ''
    } else if (typeof button === 'object') {
      classNames += button.noPadding ? 'NppModal__button-no-padding ' : ''
      classNames += button.position === 'right' ? 'NppModal__button-right ' : ''
      classNames += button.position === 'left' ? 'NppModal__button-left ' : ''
    }
    return classNames.trim()
  }

  const renderFooterButton = (button, index, onClose) => {
    if (typeof button === 'string') {
      return (
        <NppButton variant="contained" onClick={onClose}>
          {button}
        </NppButton>
      )
    }

    return (
      <NppButton
        key={index}
        variant={button.variant || 'contained'}
        onClick={button.onClick}
        loading={button.loading}
        disabled={button.disabled}
        hidden={button.hidden}
        color={button.color || 'primary'}>
        {button.text}
      </NppButton>
    )
  }

  const renderFooter = (footer, button, onCloseModal, t) => {
    // Return the custom footer if provided
    if (footer) return footer

    const footerClassNames = getFooterClassNames(button)

    return (
      <Box className={footerClassNames}>
        {Array.isArray(button)
          ? button.map((btn, index) => renderFooterButton(btn, index, onCloseModal))
          : renderFooterButton(button, 0, onCloseModal)}
      </Box>
    )
  }

  const propAttributes = rest
  if (preventOutsideClick) propAttributes.disableEscapeKeyDown = true

  return (
    <Modal
      {...propAttributes}
      className={`NppModal__default ${className}`}
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className="NppModal__wrapper">
        {renderHeader(header, title)}
        {renderBody(children)}
        {renderFooter(footer, button, onCloseModal, t)}
      </Box>
    </Modal>
  )
}

export default NppModal
