import * as React from 'react'
import i18n from 'i18n'
import InfoCardBlock from '@ui/Blocks/Shared/CardBlock/InfoCardBlock'
import { numberToPercentage } from '@utilities/index'

interface InfoCardSectionProps {
  seatUsageData: {
    used_seat: number
    paid_seat: number
    unprotected_account_count: string | number
  }
}

const InfoCardSection = ({ seatUsageData }: InfoCardSectionProps) => {
  const { t } = i18n

  return (
    <div className="SeatUsageReport__info-wrapper">
      <InfoCardBlock
        icon={<i className="solid-pie" />}
        title={`${numberToPercentage(seatUsageData?.used_seat, seatUsageData?.paid_seat)}%`}
        subtitle={` ${t('reporting_dashboard.seat_usage_report.card.title')}`}
        description={t('reporting_dashboard.seat_usage_report.card.description', {
          paid_seat: seatUsageData?.used_seat ?? 0,
          total_paid: seatUsageData?.paid_seat ?? 0
        })}
      />
    </div>
  )
}

export default InfoCardSection
